import React from 'react';
import DialogTitle from '@mui/material/DialogTitle';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import TextField from '@mui/material/TextField';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';
import { Grid } from '@mui/material';
import UploadAvatar from '../../components/UploadAvatar';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import { connect } from "react-redux";
import { SNACK_ERROR } from '../../constants/common';
import * as Api from '../../services/api/common-api'
import { withSnackbar } from 'notistack';
import { FormRow } from '../../components/FormRow';
import Password from './../../components/Password';
import { ThirtyFpsRounded } from '@mui/icons-material';

class UserDialog extends React.Component {
    render() {
        console.log(this.props)
        return (
            <Dialog open={this.props.open} fullWidth maxWidth="sm">
                <DialogTitle>
                    <Grid container>
                        <Grid item xs={10} style={{ alignSelf: 'center' }}>{this.props.editableItem ? "Edit user" : "New user"}</Grid>
                        <Grid item xs={2} style={{textAlign: 'right'}}>
                            <IconButton onClick={this.onCancelClick}><CloseIcon /></IconButton>
                        </Grid>
                    </Grid>
                </DialogTitle>
                <DialogContent>
                    <div style={{ display: "flex", flexDirection: "column" }}>
                        <FormRow>
                            <TextField
                                name="Name"
                                required
                                label="Name"
                                size="small"
                                defaultValue={(this.props.editableItem) ? this.props.editableItem.name : ""}
                                onChange={this.onNameChange}
                                fullWidth
                            />
                        </FormRow>
                        <FormRow>
                            <TextField
                                name="Email"
                                required
                                label="E-mail"
                                size="small"
                                helperText={this.state.emailVerifyText}
                                defaultValue={(this.props.editableItem) ? this.props.editableItem.email : ""}
                                error={this.state.emailVerifyText.length > 0}
                                onChange={this.onEmailChange}
                                fullWidth
                            />
                        </FormRow>
                        <FormRow>
                            <TextField
                                name="PhoneNumber"
                                required
                                label="Mobile"
                                size="small"
                                defaultValue={(this.props.editableItem) ? this.props.editableItem.phoneNumber : ""}
                                onChange={this.onPhoneNumberChange}
                                fullWidth
                            />
                        </FormRow>
                        <FormRow>
                            <Password
                                defaultValue={(this.props.editableItem) ? this.props.editableItem.plainPassword : ""}
                                onChange={this.onPlainPasswordChange}
                                value={this.currentPlainPassword()}                                
                            />
                        </FormRow>
                        <FormRow>
                            <TextField
                                name="ApiKey"
                                required
                                label="Api key"
                                size="small"
                                defaultValue={(this.props.editableItem) ? this.props.editableItem.apiKey : ""}
                                onChange={this.onApiKeyChange}
                                fullWidth
                            />
                        </FormRow>
                        <FormRow>
                            <TextField
                                name="PriceLabApiKey"
                                required
                                label="PriceLab Api Key"
                                size="small"
                                defaultValue={(this.props.editableItem) ? this.props.editableItem.priceLabApiKey : ""}
                                onChange={this.onPriceLabApiKeyChange}
                                fullWidth
                            />
                        </FormRow>

                        <FormRow>
                            <UploadAvatar selectedFile={this.state.file} onSelectFile={this.onSelectFile} currentItem={this.props.editableItem} />
                        </FormRow>

                       

                    </div>
                    
                </DialogContent>
                <DialogActions>
                    <Button onClick={this.onOkClick} color="primary">OK</Button>
                    <Button onClick={this.onCancelClick} color="primary">Cancel</Button>
                </DialogActions>
            </Dialog>
        );
    }


    constructor(props) {
        super(props);
        this.flushState();
    }

    flushState = async () => {
        const initialState = {
            name: '',
            email: '',
            emailVerifyText: '',
            phoneNumber:'',
            plainPassword:'',
            apiKey: '',
            priceLabApiKey: '',
            file: null
        };
        if (this.state) await this.setState(initialState)
        else this.state = initialState;
    }

    // Name
    currentName = () => this.state.name !== '' ? this.state.name :
        this.props.editableItem ? this.props.editableItem.name :
            '';
    onNameChange = (evt) => this.setState({ name: evt.currentTarget.value })

    // Email
    currentEmail = () => this.state.email !== '' ? this.state.email :
        this.props.editableItem ? this.props.editableItem.email :
            '';
    onEmailChange = (evt) => {
        const isMailCorrect = /\S+@\S+\.\S+/.test(String(evt.currentTarget.value).toLowerCase());

        this.setState({ email: evt.currentTarget.value, emailVerifyText: isMailCorrect ? '' : 'Invalid email address' })        
    }

    // PhoneNumber
    currentPhoneNumber = () => this.state.phoneNumber !== '' ? this.state.phoneNumber :
        this.props.editableItem ? this.props.editableItem.phoneNumber :
            '';
    onPhoneNumberChange = (evt) => this.setState({ phoneNumber: evt.currentTarget.value })


    // plainPassword
    currentPlainPassword = () => this.state.plainPassword !== '' ? this.state.plainPassword :
        this.props.editableItem ? this.props.editableItem.plainPassword :
            '';
    onPlainPasswordChange = (evt) => this.setState({ plainPassword: evt.currentTarget.value })

    // ApiKey
    currentApiKey = () => this.state.apiKey !== '' ? this.state.apiKey :
        this.props.editableItem ? this.props.editableItem.apiKey :
            '';
    onApiKeyChange = (evt) => this.setState({ apiKey: evt.currentTarget.value })

    // PriceLab ApiKey
    currentPriceLabApiKey = () => this.state.priceLabApiKey !== '' ? this.state.priceLabApiKey :
        this.props.editableItem ? this.props.editableItem.priceLabApiKey : '';

    onPriceLabApiKeyChange = (evt) => this.setState({priceLabApiKey: evt.currentTarget.value })

    

    // Avatar
    onSelectFile = (f) => this.setState({ file: f })

    

    onOkClick = async () => {
        if (this.state.emailVerifyText !== '') {
            this.props.enqueueSnackbar('There are errors on form, please fix them before saving', SNACK_ERROR)
            return;
        }

        await this.setState({
            name: this.currentName(),
            email: this.currentEmail(),
            phoneNumber: this.currentPhoneNumber(),
            plainPassword: this.currentPlainPassword(),
            apiKey: this.currentApiKey(),
            priceLabApiKey: this.currentPriceLabApiKey()
            //avatar?
            
        })


        this.props.onDialogClose(this.state);
        this.flushState();
    }

    onCancelClick = () => { this.props.onDialogClose(); this.flushState(); }




}

const mapStateToProps = (state) => {

    return {
        currentUser: state.didoReducer.currentUser
    }
}

export default connect(mapStateToProps)(withSnackbar(UserDialog));