import React from 'react';
import DidoDrawer from '../components/Drawer'
import { connect } from "react-redux";
import { withSnackbar } from 'notistack'; 
import { BasePage } from './BasePage';
import * as Api from '../services/api/common-api'
import Grid from '@mui/material/Grid';
import PropertiesSelectorChbx from '../components/PropertiesSelectorChbx';
import Toolbar from '@mui/material/Toolbar';
import AppBar from '@mui/material/AppBar';
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend,
  } from 'chart.js';

import { Bar, Doughnut, getElementAtEvent  } from 'react-chartjs-2'
import Paper from '@mui/material/Paper';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import  Container  from '@mui/material/Container';
import LoadingIndicator from '../components/LoadingIndicator';

  ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend,
    ChartDataLabels
  );

  
const perMonthChartOptions = {
    indexAxis: 'y',
    elements: {
        bar: {
            borderWidth: 2,
        },
    },
    responsive: true,
    maintainAspectRatio: false,
    legend: {
        display:false
    },
    scales: {
        x: {
            ticks: {                
                font: {
                    size: 16
                },
                callback: function (value, index, values) {
                    return '€ ' + value;
                }
            }
        },
        y:{ticks:{font:{size:16}}}
    },

    plugins: {
          legend: {
            display: false
          },
          tooltip: {
            callbacks: {
                label: function (context) {
                    var label = context.dataset.label || '';
                    if (context.parsed.y !== null) {
                        label += '  € ' + Math.round(context.parsed.x);
                    }
                    return label;
                }
            }
        },
        title: {
            display: true,
            text: 'Revenue per month',
            font: {size: 25}
        },
        datalabels: {
            color: 'white',
            anchor: 'end',
            align: 'left',
            formatter: (value, context) => value > 100 ? '€ ' + Math.round(value) : '',
            font: {
                size: 18,
                weight: 'bold'
            }
        }
    },
    
};




class ReportRevenuePage extends BasePage {
    
    constructor(props) {
        super(props, {
            selectedPropertyIds: props.currentUser.properties.map(p => p.id),
            ytodItems: [],
            selectedMonthItems: [],
            singleMonthName: '',
            singleMonthIndex: 11
        })

        this.myRef = React.createRef();
    };

    onClick = async (event) => {
        var elements = getElementAtEvent(this.myRef.current, event);
        if (elements.length > 0) {
            var MonthIndex = elements[0].index;

            await this.setState({ singleMonthName: this.state.items[MonthIndex].monthName, singleMonthIndex: MonthIndex, selectedMonthItems: this.state.items[MonthIndex].byOta })
            this.myRef.current.update();
        }
        console.log(getElementAtEvent(this.myRef.current, event));
    }

    componentDidMount() {
        console.log("load report")
        this.onLoadReport()
    }
  

    onLoadReport = async () => {
        console.log("onLoadReport")        
        console.log(this.state.selectedPropertyIds);
        if (this.state.selectedPropertyIds.length > 0) {
            this.getItems(Api.getRevenueReport, this.state.selectedPropertyIds, (res) => {
                console.log(this.state.loading);
                 this.setState({
                    items: res.data.reportPerMonth,
                    ytodItems: res.data.ytod.items,
                    selectedMonthItems: res.data.reportPerMonth.length > 0 ? res.data.reportPerMonth[this.state.singleMonthIndex].byOta : [],
                    singleMonthName: res.data.reportPerMonth.length > 0 ? res.data.reportPerMonth[this.state.singleMonthIndex].monthName : '',

                })
                this.forceUpdate()
            },
                "Error loading revenue data")
        }
        else {
            console.log("empty state")
            await this.setState({
                items: [],
                ytodItems: [],
                selectedMonthItems: [],                
                singleMonthName: '',
                singleMonthIndex: 11
            })
            this.forceUpdate()
        }
    }

    onPropertySelect = async (event) => {
        const value = event.target.value;
        
        if (value[value.length - 1] === "all") {
            await this.setState({ selectedPropertyIds: this.state.selectedPropertyIds.length === this.props.currentUser.properties.length ? [] : this.props.currentUser.properties.map(p => p.id) })
            this.onLoadReport()
            return;
        }
        
        const selectedPropertyIds = typeof value === 'string' ? value.split(',') : value;
        await this.setState({ selectedPropertyIds : selectedPropertyIds });        
        this.onLoadReport()
    };

    renderDesktopLayout = (ytodData, ytodChartOptions, monthData, singleMonthChartOptions, perMonthData) => {
        return (
            <Grid container spacing={2}>

                <Grid item xs={4} >
                    <Grid container alignItems="center" spacing={2}>
                        <Grid item xs={12}>
                            <Paper style={{ textAlign: "center", verticalAlign: "center", minHeight: '36vh' }}>
                                <Doughnut data={ytodData} options={ytodChartOptions} style={{ paddingLeft: 20, paddingRight: 20, paddingTop: 20, paddingBottom: 20, }} />
                            </Paper>
                        </Grid>
                        <Grid item xs={12}>
                            <Paper style={{ textAlign: "center", verticalAlign: "center", minHeight: '36vh' }}>
                                <Doughnut data={monthData} options={singleMonthChartOptions} style={{ paddingLeft: 20, paddingRight: 20, paddingTop: 20, paddingBottom: 20 }} />
                            </Paper>
                        </Grid>
                    </Grid>
                </Grid>

                <Grid item xs={8} alignItems="center">
                    <Paper style={{ textAlign: "center", verticalAlign: "center", minHeight: '74vh' }}>
                        <Bar ref={this.myRef} options={perMonthChartOptions} data={perMonthData} style={{ paddingLeft: 50, paddingRight: 50, paddingTop: 50, paddingBottom: 50, height: '74vh' }} onClick={this.onClick} />
                    </Paper>
                </Grid>
            </Grid>
        )
    }

    renderMobileLayout = (ytodData, ytodChartOptions, monthData, singleMonthChartOptions, perMonthData) => {
        return (
            <Grid container spacing={2}>

                {/* <Grid item xs={4} > */}
                    <Grid container alignItems="center" spacing={3}>
                        <Grid item xs={12}>
                            <Paper style={{ textAlign: "center", verticalAlign: "center", minHeight: '40vh' }}>
                                <Doughnut data={ytodData} options={ytodChartOptions} style={{ paddingLeft: 20, paddingRight: 20, paddingTop: 20, paddingBottom: 20, }} />
                            </Paper>
                        </Grid>
                        <Grid item xs={12}>
                            <Paper style={{ textAlign: "center", verticalAlign: "center", minHeight: '40vh' }}>
                                <Doughnut data={monthData} options={singleMonthChartOptions} style={{ paddingLeft: 20, paddingRight: 20, paddingTop: 20, paddingBottom: 20 }} />
                            </Paper>
                        </Grid>
                        <Grid item xs={12} alignItems="center">
                    <Paper style={{ textAlign: "center", verticalAlign: "center", minHeight: '82vh' }}>
                        <Bar ref={this.myRef} options={perMonthChartOptions} data={perMonthData} style={{ paddingLeft: 50, paddingRight: 50, paddingTop: 50, paddingBottom: 50, height: '82vh' }} onClick={this.onClick} />
                    </Paper>
                </Grid>
                    </Grid>
                {/* </Grid> */}

                
            </Grid>
        )
    }

    render() {

        const monthData = {
            labels: this.state.selectedMonthItems.map(i => i.otaName),
            datasets: [{
                label: 'Month name',
                data: this.state.selectedMonthItems.map(i => i.revenue),
                backgroundColor: [
                    '#B0C8EB',
                    '#FAAFB1', 
                    '#95C999'
                ],
                //   hoverOffset: 4
            }]
        };

        const ytodData = {
            labels: this.state.ytodItems.map(i => i.ota),
            datasets: [{
                label: 'YTOD',
                data: this.state.ytodItems.map(i => i.revenue),
                backgroundColor: [
                    '#B0C8EB',
                    '#FAAFB1',
                    '#95C999'
                ],
                //   hoverOffset: 4
            }]
        };


        const perMonthData = {
            labels: this.state.items.map(i => i.monthName),
            datasets: [
                {
                    label: 'Revenue',
                    data: this.state.items.map(i => i.revenue),
                    //borderColor: 'rgb(157, 188, 189)',
                    backgroundColor: this.state.items.map((el, index) => index === this.state.singleMonthIndex? 'rgb(77, 108, 109)' : 'rgb(157, 188, 189)'),
                }
            ],
        };

        console.log(this.state.ytodItems)

        const ytodChartOptions = {
    
            responsive: true,
            maintainAspectRatio: false,
            plugins: {
                title: {
                    display: true,
                    text: `YTOD : € ${Math.round(this.state.ytodItems.reduce((total, obj) => obj.revenue + total,0))}`,
                    font: {size: 25}
                },
                legend: {
                    display: true,
                    position: 'right',
                },
                datalabels: {
                    color: 'white',
                    formatter: function(value, context) {
                        return  '€ ' + Math.round(value);
                      },
                    font: {
                        size: 18,
                        weight: 'bold'
                    }
                }
            },
            
        };

        const singleMonthChartOptions = {
    
            responsive: true,
            maintainAspectRatio: false,
            plugins: {
                title: {
                    display: true,
                    text: `${this.state.singleMonthName} : € ${Math.round(this.state.selectedMonthItems.reduce((total, obj) => obj.revenue + total,0))}`,
                    font: {size: 25}
                },
                legend: {
                    display: true,
                    position: 'right',
                },
                datalabels: {
                    color: 'white',
                    formatter: function(value, context) {
                        return  '€ ' + Math.round(value);
                      },
                    font: {
                        size: 18,
                        weight: 'bold'
                    }
                }
            },
            
        };

        

        return (
            <div>
                <DidoDrawer style={{ zIndex: 2000 }}
                    pageTitle="Revenue"
                    noFab
                />
                {
                    this.props.currentUser.properties.length > 1 ?
                        <AppBar position="fixed" style={{ top: '56px', background: 'white' }}>
                            <Toolbar style={{ background: 'white', minHeight: '76px' }} position="fixed">
                                <PropertiesSelectorChbx
                                    selectedIds={this.state.selectedPropertyIds}
                                    properties={this.props.currentUser.properties}
                                    onChange={this.onPropertySelect}
                                    isMobile={this.isMobile()}
                                />
                            </Toolbar>
                        </AppBar>
                        : <></>
                }
                <LoadingIndicator loading={this.state.loading} />
                <Container maxWidth="xl" style={{ marginTop: this.props.currentUser.properties.length > 1 ? '170px' : '100px', minHeight: '100vh' }} align="center">
                    {
                        this.isMobile() ? 
                    this.renderMobileLayout(ytodData, ytodChartOptions, monthData, singleMonthChartOptions, perMonthData) :
                    this.renderDesktopLayout(ytodData, ytodChartOptions, monthData, singleMonthChartOptions, perMonthData)
                }
                    
                </Container>
            </div>);
    }


}

const mapStateToProps = (state) =>  ({  currentUser: state.didoReducer.currentUser })  
  
export default withSnackbar(connect(mapStateToProps)(ReportRevenuePage));