import React from 'react';
import * as Api from '../services/api/common-api'
import { SNACK_SUCCESS } from './../constants/common';
import {withSnackbar} from 'notistack';

export class BasePage extends React.Component {
    
    constructor(props, params) {
        super(props);
        this.state = { items: [],  dialogOpen: false, currentItem: null,  width: window.innerWidth, height: window.innerHeight, ...params};
        //console.log(this.state)
    }

    componentWillMount() {       
        window.addEventListener('resize', this.onWindowSizeChange);
    }

    onWindowSizeChange = () => {
        this.setState({width: window.innerWidth });
    };

    getItems = (promise, params, then, errorMsg) => {
        this.setState({loading: true})

        promise(params).then((res) => {
            console.log("!")
            then(res);this.setState({loading: false})} )
        .catch(err =>{
            this.setState({loading: false})
            Api.logger.errorWithSnackbar(errorMsg,this, err)});

    }

    isMobile = () => this.state.width <= 1000;

    deleteItem = (id, promise, then, successMsg, errorMsg) => {
        promise(id).then((res) => {
            this.props.enqueueSnackbar(successMsg, SNACK_SUCCESS);
            then(res)
            })
            .catch(err =>Api.logger.errorWithSnackbar(`errorMsg id=${id}`,this, err));
    }

    showDialog = async (item) => await this.setState({ currentItem: item, dialogOpen: true });
}

export default withSnackbar(BasePage)