import React from 'react';
import DidoDrawer from '../../components/Drawer'
import { connect } from "react-redux";
import { withSnackbar } from 'notistack'; 
import { BasePage } from '../BasePage';
import * as Api from '../../services/api/common-api'
import Grid from '@mui/material/Grid';
import Toolbar from '@mui/material/Toolbar';
import AppBar from '@mui/material/AppBar';
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend,
  } from 'chart.js';

  import { Bar, getElementAtEvent  } from 'react-chartjs-2'
  import Paper from '@mui/material/Paper';

  import ChartDataLabels from 'chartjs-plugin-datalabels';
import  Container  from '@mui/material/Container';
import LoadingIndicator from '../../components/LoadingIndicator';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import ListItemText from '@mui/material/ListItemText';
import GaugeChart from 'react-gauge-chart'
import moment from 'moment';
import { Typography } from '@mui/material';
import { revenueChartOptions, occupancyChartOptions } from './chartOptions';

  ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend,
    ChartDataLabels
  );

  
class ReportDashboardPage extends BasePage {

    animate = 0;
    
    constructor(props) {
        super(props, {
            selectedPropertyId: props.currentUser.properties.map(p => p.id)[0],
            futureRevenues: [],
            futureOccupancy: [],
            monthlyRevenue: 0,
            monthlyOccupancy: 0,
            monthlyCancellations: 0,
            maxMonthlyRevenue: 0,
            maxMonthlyOccupancy: 0,
            maxMonthlyCancellations: 0,
            animate: 0
        })

        this.myRef = React.createRef();
    };

    

    onClick = async (event) => {
        console.log("onClick")
    }

    componentDidMount() {
        console.log("componentDidMount")
        this.onLoadReport()
    }

    
  

    onLoadReport = async () => {
        console.log("onLoadReport") 

            this.getItems(Api.getDashboardReport, this.state.selectedPropertyId, (res) => {
                console.log(this.state.loading);
                 this.setState({
                    monthlyRevenue: res.data.monthlyRevenue,
                    monthlyOccupancy: res.data.monthlyOccupancy,
                    monthlyCancellations: res.data.monthlyCancellations,
                    maxMonthlyRevenue: res.data.maxMonthlyRevenue,
                    maxMonthlyOccupancy: res.data.maxMonthlyOccupancy,
                    maxMonthlyCancellations: res.data.maxMonthlyCancellations,
                    futureOccupancy : res.data.futureOccupancy,
                    futureRevenues : res.data.futureRevenues,
                   
                })
                this.animate = 0;
                //this.forceUpdate()
            },
                "Error loading revenue data")
   
    }

    onPropertySelect = async (event) => {

        await this.setState({ selectedPropertyId : event.target.value });
        
        this.onLoadReport()
    };

    renderReportLayout = () => {
        const revenueMonthlyData = {
            labels: this.state.futureRevenues.map(i => i.monthName),
            datasets: [
                {
                    label: 'Revenue',
                    data: this.state.futureRevenues.map(i => i.revenue),
                    backgroundColor: 'rgb(157, 188, 189)',
                }
            ],
        };

        const occupancyMonthlyData = {
            labels: this.state.futureRevenues.map(i => i.monthName),
            datasets: [
              {
                label: 'Occupancy',
                data: this.state.futureOccupancy.map(i => i.occupancy * 100),
                borderColor: 'rgb(157, 188, 189)',
                backgroundColor: 'rgba(157, 188, 189, 0.8)',
              }
            ],
          };


        const revenuePercent = this.state.maxMonthlyRevenue === 0 ? 0 : this.state.monthlyRevenue / this.state.maxMonthlyRevenue >= 1 ? 1 : this.state.monthlyRevenue / this.state.maxMonthlyRevenue;
        const occupancyPercent = this.state.maxMonthlyOccupancy === 0 ? 0 : 100 * this.state.monthlyOccupancy / this.state.maxMonthlyOccupancy >= 1 ? 1 :100 * this.state.monthlyOccupancy / this.state.maxMonthlyOccupancy;
        const cancellationsPercent = this.state.maxMonthlyCancellations === 0 ? 0 : 100 * this.state.monthlyCancellations / this.state.maxMonthlyCancellations>= 1 ? 1 :100 * this.state.monthlyCancellations / this.state.maxMonthlyCancellations;
        
        this.animate++;

        return (
            <Grid container spacing={2}>

                <Grid item xs={12} >
                    <Grid container alignItems="center" spacing={2}>
                        <Grid item xs={this.isMobile() ? 12 : 4}>
                            <Paper style={{ textAlign: "center", verticalAlign: "center" }}>
                                <Typography variant='h6'>Revenue {moment().format('MMMM')}</Typography>
                                <GaugeChart
                                colors={[ "#FF5555","#55FF55"]}
                                textColor="#444"
                                needleColor="#bbb"
                                needleBaseColor='#bbb'
                                animDelay={0}
                                animate={this.animate < 2}
                                arcPadding={0.02}
                                nrOfLevels={20}                                
                                formatTextValue={(v)=>'€ ' + Math.round(this.state.monthlyRevenue)}
                                percent={revenuePercent } />
                            </Paper>
                        </Grid>

                        <Grid item xs={this.isMobile() ? 12 : 4}>
                            <Paper style={{ textAlign: "center", verticalAlign: "center"}}>
                            <Typography variant='h6'>Occupancy {moment().format('MMMM')}</Typography>                                
                                <GaugeChart
                                colors={[ "#FF5555","#55FF55"]}
                                textColor="#444"
                                needleColor="#bbb"
                                needleBaseColor='#bbb'
                                animDelay={0}
                                animate={this.animate < 2}
                                arcPadding={0.02}
                                nrOfLevels={20}
                                formatTextValue={(v)=>Math.round(100*this.state.monthlyOccupancy)+"%"}
                                percent={occupancyPercent} />
                            </Paper>
                        </Grid>

                        <Grid item xs={this.isMobile() ? 12 : 4}>
                            <Paper style={{ textAlign: "center", verticalAlign: "center" }}>
                            <Typography variant='h6'>Cancellations {moment().format('MMMM')}</Typography>
                                <GaugeChart
                                colors={["#55FF55",  "#FF5555"]}                            
                                textColor="#444"
                                needleColor="#bbb"
                                needleBaseColor='#bbb'
                                animDelay={0}
                                animate={this.animate < 2}
                                arcPadding={0.02}
                                nrOfLevels={20}
                                formatTextValue={(v)=>Math.round(100*this.state.monthlyCancellations)+"%"}
                                percent={cancellationsPercent} />
                            </Paper>
                        </Grid>

                       
                    </Grid>
                </Grid>

                <Grid item xs={12} alignItems="center">
                    <Paper style={{ textAlign: "center", verticalAlign: "center", minHeight: '74vh' }}>
                        <Bar ref={this.myRef} options={revenueChartOptions} data={revenueMonthlyData} style={{ paddingLeft: 20, paddingRight: 20, paddingTop: 20, paddingBottom: 20, height: '74vh' }} onClick={this.onClick} />
                    </Paper>
                </Grid> 

                <Grid item xs={12} alignItems="center">
                    <Paper style={{ textAlign: "center", verticalAlign: "center", minHeight: '74vh' }}>
                        <Bar ref={this.myRef} options={occupancyChartOptions} data={occupancyMonthlyData} style={{ paddingLeft: 20, paddingRight: 20, paddingTop: 20, paddingBottom: 20, height: '74vh' }} onClick={this.onClick} />
                    </Paper>
                </Grid> 
            </Grid>
        )
    }

    render() {

        

        

        return (
            <div>
                <DidoDrawer style={{ zIndex: 2000 }}
                    pageTitle="Dashboard"
                    noFab
                />
                {
                    this.props.currentUser.properties.length > 1 ?
                        <AppBar position="fixed" style={{ top: '56px', background: 'white' }}>
                            <Toolbar style={{ background: 'white', minHeight: '76px' }} position="fixed">
                                <Select
                                    value={this.state.selectedPropertyId}
                                    
                                    onChange={this.onPropertySelect}
                                >
                                    {this.props.currentUser.properties.map((property) => (
                                        <MenuItem key={property.id} value={property.id}>
                                        
                                        <ListItemText primary={property.name} />
                                      </MenuItem>
                                    ))}
                                    </Select>
                            </Toolbar>
                        </AppBar>
                        : <></>
                }
                <LoadingIndicator loading={this.state.loading} />
                <Container maxWidth="xl" style={{ marginTop: this.props.currentUser.properties.length > 1 ? '170px' : '100px', minHeight: '100vh' }} align="center">
                    {
                     //   this.isMobile() ? 
                    //this.renderMobileLayout(ytodData, ytodChartOptions, monthData, singleMonthChartOptions, perMonthData) :
                    this.renderReportLayout()
                }
                    
                </Container>
            </div>);
    }


}

const mapStateToProps = (state) =>  ({  currentUser: state.didoReducer.currentUser })  
  
export default withSnackbar(connect(mapStateToProps)(ReportDashboardPage));