import React from 'react';
import DialogTitle from '@mui/material/DialogTitle';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import TextField from '@mui/material/TextField';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';
import { Grid } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import { connect } from "react-redux";
import { withSnackbar } from 'notistack';
import { FormRow } from '../../components/FormRow';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Visibility from '@mui/icons-material/Visibility';
import { SNACK_ERROR } from '../../constants/common';
import InputAdornment from '@mui/material/InputAdornment';
import { styled } from '@mui/material';

const SettingsFormControl = styled(FormControl)(({ theme }) => ({
    display: 'flex'
}));


class UserSettingsDialog extends React.Component {


    render() {
        if (this.props.currentUser == null){
            return (
                <div></div>
            );
        }
        return (
            <Dialog open={this.props.open} fullScreen={this.props.fullScreen}>
                <DialogTitle>
                    <Grid container>
                        <Grid item xs={10} style={{ alignSelf: 'center' }}>Settings</Grid>
                        <Grid item xs={2} style={{textAlign: 'right'}}>
                            <IconButton onClick={this.onCancelClick}><CloseIcon /></IconButton>
                        </Grid>
                    </Grid>
                </DialogTitle>
                <DialogContent>
                    <div style={{ display: "flex", flexDirection: "column" }}>
                        <FormRow>
                            <SettingsFormControl>
                                <FormLabel id="group-label">Tax settings</FormLabel>
                                <RadioGroup
                                    aria-labelledby="group-label"
                                    value={this.currentTaxType()}
                                    name="radio-buttons-group"
                                    onChange={this.onTaxTypeChange}
                                    sx={{marginTop: '10px', marginBottom: '15px'}}
                                >
                                    <FormControlLabel value={0} control={<Radio />} label={
                                        <div>
                                            <div>Percentage</div>
                                            <div><TextField
                                                name="percentage"
                                                size="small"
                                                defaultValue={this.props.currentUser.userSettings.taxPercentageValue}
                                                onChange={this.onPercentageChange}
                                                disabled={this.currentTaxType() !== 0}
                                                InputProps={{
                                                    startAdornment: <InputAdornment position="start">%</InputAdornment>,
                                                  }}
                                            /></div>
                                        </div>
                                    } >
                                        
                                    </FormControlLabel>
                                    <FormControlLabel value={1} control={<Radio />} label={
                                        <div>
                                            <div>Price per person per night</div>
                                            <div><TextField
                                                name="perPerson"
                                                size="small"
                                                defaultValue={this.props.currentUser.userSettings.taxPricePerPersonValue}
                                                onChange={this.onPerPersonChange}
                                                disabled={this.currentTaxType() !== 1}
                                                InputProps={{
                                                    startAdornment: <InputAdornment position="start">€</InputAdornment>,
                                                  }}
                                            /></div>
                                        </div>
                                    } />
                                </RadioGroup>
                            </SettingsFormControl>
                            
                        </FormRow>
                        <FormRow>
                            <SettingsFormControl>
                                <FormLabel id="group-label">Fixed cost</FormLabel>
                                <TextField
                                    name="fixed cost"
                                    size="small"
                                    defaultValue={this.props.currentUser.userSettings.fixedCost}
                                    onChange={this.onFixedCostChange}
                                    fullWidth
                                    InputProps={{
                                        startAdornment: <InputAdornment position="start">€</InputAdornment>,
                                    }}
                                />
                            </SettingsFormControl>

                        </FormRow>

                        <FormRow>
                            <SettingsFormControl>
                                <FormLabel id="sales-label">Target average monthly sales</FormLabel>
                                <TextField
                                    name="Target average monthly sales"
                                    size="small"
                                    defaultValue={this.props.currentUser.userSettings.targetAverageMonthlySales}
                                    onChange={this.onTargetAverageMonthlySalesChange}
                                    fullWidth
                                />
                            </SettingsFormControl>
                        </FormRow>

                        <FormRow>
                            <SettingsFormControl>
                                <FormLabel>Target average monthly occupancy rate (%)</FormLabel>
                                <TextField                                   
                                    size="small"
                                    defaultValue={this.props.currentUser.userSettings.targetAverageMonthlyOccupancyRate}
                                    onChange={this.onTargetAverageMonthlyOccupancyRateChange}
                                    fullWidth
                                />
                            </SettingsFormControl>
                        </FormRow>

                        <FormRow>
                            <SettingsFormControl>
                                <FormLabel>Target average cancellations (%)</FormLabel>
                                <TextField                                   
                                    size="small"
                                    defaultValue={this.props.currentUser.userSettings.targetAverageCancellations}
                                    onChange={this.onTargetAverageCancellationsChange}
                                    fullWidth
                                />
                            </SettingsFormControl>
                        </FormRow>


                        <FormRow>
                            <Accordion 
                            elevation={0}
                            square
                            >
                                <AccordionSummary
                                    expandIcon={<ExpandMoreIcon />}
                                    aria-controls="panel1a-content"
                                    id="panel1a-header"
                                >
                                    <Typography><Visibility /> Change password</Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <FormRow>
                                        <TextField
                                            type="password"
                                            label="Current password"
                                            size="small"
                                            onChange={this.onCurrentPasswordChange}
                                            value={this.currentPassword()}
                                        />
                                    </FormRow>
                                    <FormRow>
                                        <TextField
                                            type="password"
                                            label="New password"
                                            size="small"
                                            onChange={this.onNewPassword1Change}
                                            value={this.currentNewPassword1()}
                                        />
                                    </FormRow>
                                    <FormRow>
                                        <TextField
                                            type="password"
                                            label="Repeat New password"
                                            size="small"
                                            onChange={this.onNewPassword2Change}
                                            value={this.currentNewPassword2()}
                                        />
                                    </FormRow>
                                </AccordionDetails>
                            </Accordion>
                        </FormRow>
                    </div>
                    
                </DialogContent>
                <DialogActions>
                    <Button onClick={this.onOkClick} color="primary">OK</Button>
                    <Button onClick={this.onCancelClick} color="primary">CANCEL</Button>
                </DialogActions>
            </Dialog>
        );
    }


    constructor(props) {
        super(props);
        this.flushState();
    }

    flushState = async () => {
        const initialState = {
            taxType: -1,
            taxPercentageValue: 0,
            taxPricePerPersonValue: 0,
            fixedCost: 0,
            targetAverageMonthlySales: 0,
            targetAverageMonthlyOccupancyRate: 0,
            targetAverageCancellations: 0,
            currentPassword: '',
            newPassword1:'',
            newPassword2:''
        };
        if (this.state) await this.setState(initialState)
        else this.state = initialState;
    }

    // TaxType
    currentTaxType = () => this.state.taxType !== -1 ? this.state.taxType :
        this.props.currentUser.userSettings.taxType;
    onTaxTypeChange = (evt) => {
        
        this.setState({ taxType: +evt.currentTarget.value })
    }

    // Percentage
    currentPercentage = () => this.state.taxPercentageValue !== 0 ? this.state.taxPercentageValue :
        this.props.currentUser.userSettings.taxPercentageValue;
    onPercentageChange = (evt) => {
        this.setState({ taxPercentageValue: evt.currentTarget.value })    
    }

    // Fixed cost
    currentFixedCost = () => this.state.fixedCost !== 0 ? this.state.fixedCost :
        this.props.currentUser.userSettings.fixedCost;
    onFixedCostChange = (evt) => {
        this.setState({ fixedCost: evt.currentTarget.value })    
    }

    // TargetAverageMonthlySales
    currentTargetAverageMonthlySales= () => this.state.targetAverageMonthlySales !== 0 ? this.state.targetAverageMonthlySales :
    this.props.currentUser.userSettings.targetAverageMonthlySales;

    onTargetAverageMonthlySalesChange = (evt) => {
        this.setState({ targetAverageMonthlySales: evt.currentTarget.value })    
    }

    // TargetAverageMonthlyOccupancyRate
    currentTargetAverageMonthlyOccupancyRate= () => this.state.targetAverageMonthlyOccupancyRate !== 0 ? this.state.targetAverageMonthlyOccupancyRate :
    this.props.currentUser.userSettings.targetAverageMonthlyOccupancyRate;

    onTargetAverageMonthlyOccupancyRateChange = (evt) => {
        this.setState({ targetAverageMonthlyOccupancyRate: evt.currentTarget.value })    
    }

    // TargetAverageCancellations
    currentTargetAverageCancellations= () => this.state.targetAverageCancellations !== 0 ? this.state.targetAverageCancellations :
    this.props.currentUser.userSettings.targetAverageCancellations;

    onTargetAverageCancellationsChange = (evt) => {
        this.setState({ targetAverageCancellations: evt.currentTarget.value })    
    }

    // PerPerson
    currentPerPerson = () => this.state.taxPricePerPersonValue !== 0 ? this.state.taxPricePerPersonValue :
    this.props.currentUser.userSettings.taxPricePerPersonValue;
    onPerPersonChange = (evt) => this.setState({ taxPricePerPersonValue: evt.currentTarget.value })

    // Current password
    currentPassword = () => this.state.currentPassword
    onCurrentPasswordChange = (evt) => this.setState({ currentPassword: evt.currentTarget.value })

    // New password 1
    currentNewPassword1 = () => this.state.newPassword1
    onNewPassword1Change = (evt) => this.setState({ newPassword1: evt.currentTarget.value })

    // New password 2
    currentNewPassword2 = () => this.state.newPassword2
    onNewPassword2Change = (evt) => this.setState({ newPassword2: evt.currentTarget.value })


    onOkClick = async () => {     
        
        if (this.currentNewPassword1() !== this.currentNewPassword2()) {
            this.props.enqueueSnackbar("New password and it confirmation doesn't match", SNACK_ERROR)
            return;
        }
        
        await this.setState({
            id: this.props.currentUser.userSettings.id,
            taxType: this.currentTaxType(),
            taxPercentageValue: this.currentPercentage(),
            taxPricePerPersonValue: this.currentPerPerson(),
            targetAverageMonthlySales : this.currentTargetAverageMonthlySales(),
            targetAverageMonthlyOccupancyRate : this.currentTargetAverageMonthlyOccupancyRate(),
            targetAverageCancellations: this.currentTargetAverageCancellations(),
            fixedCost: this.currentFixedCost(),
            currentPassword: this.currentPassword(),
            newPassword: this.currentNewPassword1()
        });

        await this.props.onDialogClose(this.state);
        this.flushState();
    }

    onCancelClick = () => { this.props.onDialogClose(); this.flushState(); }
}


export default connect((state)=>({currentUser: state.didoReducer.currentUser}))(withSnackbar(UserSettingsDialog));