import React from 'react';
import { EMPTY_GUID } from '../../constants/common';

export class BaseDialog extends React.Component {
  _initialState = null;
  constructor(props, initialState) {
    super(props);

    this._initialState = initialState;
    this._initialState.width = window.innerWidth;

    this.flushState();
  }

  flushState = () => {
    if (this.state) this.setState(this._initialState)
    else this.state = this._initialState;

  }

  componentWillMount() {
    window.addEventListener('resize', this.onWindowSizeChange);
  }

  onWindowSizeChange = () => {
    this.setState({ width: window.innerWidth });
  };

  currentProp = (propName, apiName, defaultValue) => {
    //console.log(`currentProp '${propName}'='${this.state[propName]}'`)
    if (this.state.hasOwnProperty(propName) && (this.state[propName] != null && this.state[propName] !== '' && this.state[propName] !== EMPTY_GUID)) {
      return this.state[propName]
    }

    //console.log(this.props.currentItem)
    if (this.props.currentItem) {
      return this.props.currentItem[apiName];
    }

    return defaultValue;
  }

  updateState = async (id, event) => {    
    const newData = {};
    newData[id] = event.target.value;
    
    await this.setState(newData)
    //console.log(this.state)
  }

  // onDialogClose = async (fixState, isCanceled) => {
  //   fixState.isCanceled = isCanceled
  //   await this.setState(fixState)

  //   this.props.onDialogClosed(this.state);
  //   this.flushState();
  // }

  onDialogCancel = () =>{
    this.props.onDialogClose();
    this.flushState();
  }

  onDialogSave = async (fixState) => {    
    await this.setState(fixState)

    this.props.onDialogClose(this.state);
    this.flushState();
  }
}