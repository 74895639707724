import axios from "./axios";
import { TOKEN_KEY, SNACK_ERROR } from '../../constants/common';

 
export const logger = {
  warning(eventName, object) {
    
    if (!localStorage.getItem(TOKEN_KEY)) {
      return;
    }

    axios.post("JsLog/warning?eventname=" + eventName, { url: window.location.href, text: JSON.stringify(object), time: new Date() })
  },

  error(eventName, object) {
    if (!localStorage.getItem(TOKEN_KEY)) {
      return;
    }

    axios.post("JsLog/error?eventname=" + eventName, { url: window.location.href, text: JSON.stringify(object), time: new Date() })
  },

  errorWithSnackbar(message, objectThis, error){

    if (objectThis != null && objectThis.props != null){
      objectThis.props.enqueueSnackbar(message, SNACK_ERROR);
    }

    if (!localStorage.getItem(TOKEN_KEY)) {
      return;
    }

    axios.post("JsLog/error?eventname=" + message, { url: window.location.href, text: ""+error, time: new Date() })

  }
}

// User
export const logIn = (params) => axios.post("BnbUser/Login", params, { headers: { 'Content-Type': 'application/json' } });
export const forgotPassword = (params) => axios.post("BnbUser/ForgotPassword", params);
export const changePasswordByRecoveryCode = (params) => axios.post("BnbUser/ChangePasswordByRecoveryCode", params);
export const getCurrentUser = () => axios.get("BnbUser/GetCurrentUser");


export const getUsers = () => axios.get("BnbUser/GetUsers");
export const createUserWithUpload = (params) => axios.post("BnbUser/CreateWithUpload", params, { headers: { 'Content-Type': 'multipart/form-data' } });

export const updateUserWithUpload = (params) => axios.post("BnbUser/UpdateWithUpload", params, { headers: { 'Content-Type': 'multipart/form-data' } });

export const deleteUser = (id) => axios.post("BnbUser/DeleteUser", null, { params: { deleteGuid: id } });

// Properties
export const getAllProperties = (user) => axios.post("Property/GetPropertiesForUser", user)
export const updateProperties = (props) => axios.post("Property/UpdateProperties", props)


// Calendar
export const getCalendarView = (filter) => axios.post("Booking/GetCalendarView", filter);
export const getBooking = (id, propId) => axios.post("Booking/GetBooking", null, { params: { bookingId: id, propId } });
export const createBlackout = (params) => axios.post("Booking/CreateBlackout", params);
export const updateBlackout = (params) => axios.post("Booking/UpdateBlackout", params);
export const deleteBlackout = (params) => axios.post("Booking/DeleteBlackout", null, {params:{id:params.id, propId: params.propId}});
export const updatePrice = (params) => axios.post("Booking/UpdateFreeDayPrice", params);

// NightLog
export const getNightLog = (filter) => axios.post("NightLog/GetNightLog", filter);
export const createNightLog = (params) => axios.post("NightLog/Create", params);
export const updateNightLog = (params) => axios.post("NightLog/Update", params);
export const deleteNightLog = (guid) => axios.post("NightLog/Delete", null, { params: { guid: guid } });
export const exportToPdf = (filter) => axios.get("NightLog/ExportToPdf", {responseType: 'arraybuffer',params: {start: filter.start, end: filter.end, propertyId: filter.propertyId}});

export const getAllCountries = () => axios.get("Country/GetAll")
export const getAllCitiesByCountry = (countryId) => axios.get("City/GetAllByCountry", {params: {countryId: countryId}})


// UpdateSettings
export const updateUserSettings = (params) => axios.post("UserSettings/UpdateSettingsForUser", params);


// ReportPage
export const getOccupancyReport = (propertyIds) => axios.post("Report/GetOccupancyReport", propertyIds)
export const getRevenueReport = (propertyIds) => axios.post("Report/GetRevenueReport", propertyIds)
export const getDashboardReport = (propertyId) => axios.post("Report/GetDashboardReport",  null, { params: { propertyId } } )
export const getNeightborhoodTableReport = (propertyId) => axios.post("Report/GetNeighborhoodData",  null, { params: { propertyId } } )
