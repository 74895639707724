import React from "react";
import Grid from "@mui/material/Grid";
import blue from "@mui/material/colors/blue";
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import { Avatar } from "@mui/material";
import { Button } from '@mui/material';
import { styled } from "@mui/material";

const UploadContainer = styled('div')(({ theme }) => ({
  backgroundColor: theme.palette.background.paper,
  display: "flex",
  justifyContent: "center",
  alignItems: "flex-end"
}));

class UploadAvatar extends React.Component {
  state = {
    mainState: this.props.currentItem == null ? "initial" : "uploaded", // initial / uploaded
    previewFile: null
  };

  handleUploadClick = (event) => {
    if (event.target.files.length === 0) return;
    var file = event.target.files[0];
    
    const reader = new FileReader();
    reader.readAsDataURL(file);

    reader.onloadend = function () {
      this.setState({
        previewFile: reader.result // was [reader.result]
      });
      
      if (this.props.onPreviewLoaded)
        this.props.onPreviewLoaded(reader.result)

    }.bind(this);

    this.props.onSelectFile(event.target.files[0], this.state.previewFile)
    this.setState({
      mainState: "uploaded"
    });
  };

  renderInitialState() {
    const { classes } = this.props;

    return (
      <React.Fragment>
        
          <Grid container justify="flex-start" alignItems="flex-start">
            <input
              accept="image/*"
              style={{display:'none'}}              
              id="contained-button-file"
              type="file"
              onChange={this.handleUploadClick}
            />
            <label htmlFor="contained-button-file" style={{display:'grid'}}>
            
              <Button variant="contained" startIcon={<CloudUploadIcon />} component="span" style={{backgroundColor: '#4AB897', color: 'white', marginTop: 10}}>
                 Upload new avatar
              </Button>
            </label>
          </Grid>
        
      </React.Fragment>
    );
  }



  renderUploadedState() {

    return (
      <React.Fragment>
        <Grid container justify="flex-start" alignItems="flex-start">
          <input
            accept="image/*"
            id="contained-button-file"
            type="file"
            onChange={this.handleUploadClick}
          />
          <label htmlFor="contained-button-file" style={{display:'grid'}}>

            <Button variant="outlined" component="span" style={{ color: blue[900], marginTop: 10 }}>
              <Avatar
                src={this.props.currentItem != null && this.state.previewFile == null ? this.props.currentItem.avatar : this.state.previewFile}
                style={{ width: 32, height: 32, marginRight: 10, color: blue[900], marginTop: 10 }} /> Avatar
            </Button>

          </label>
        </Grid>
      </React.Fragment>
    );
  }


  render() {

    return (
      <React.Fragment>
        <UploadContainer>          
            {(this.state.mainState === "initial" && this.renderInitialState()) ||               
              (this.state.mainState === "uploaded" && this.renderUploadedState())}          
        </UploadContainer>
      </React.Fragment>
    );
  }
}

//export default withStyles(styles, { withTheme: true })(UploadAvatar);
export default (UploadAvatar);
