import * as React from 'react';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import ListItemText from '@mui/material/ListItemText';
import Select from '@mui/material/Select';
import Checkbox from '@mui/material/Checkbox';
import ListItemIcon from "@material-ui/core/ListItemIcon";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      //width: 250,
    },
  },
};


export default function PropertiesSelectorChbx(props) {

  const isAllSelected = props.properties.length > 0 && props.selectedIds.length === props.properties.length;
//console.log(props.isMobile)
  return (
    <div>
      <FormControl sx={{ m: 1, width: props.isMobile ? 200 : 300}}>
        <InputLabel id="multiple-checkbox-label">Property</InputLabel>
        <Select
          labelId="multiple-checkbox-label"
          multiple
          value={props.selectedIds}
          onChange={props.onChange}
          input={<OutlinedInput label="Properties" />}
          renderValue={(s) => props.properties.filter(p => s.includes(p.id)).map(p => p.name).join(', ')}
          MenuProps={MenuProps}
        >
          <MenuItem value="all" >
            <ListItemIcon>
              <Checkbox
                checked={isAllSelected}
                indeterminate={props.selectedIds.length > 0 && props.selectedIds.length < props.properties.length} />
            </ListItemIcon>
            <ListItemText primary="Select All" />
          </MenuItem>
          {props.properties.map((property) => (
            <MenuItem key={property.id} value={property.id}>
              <Checkbox checked={props.selectedIds.indexOf(property.id) > -1} />
              <ListItemText primary={property.name} />
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </div>
  );
}