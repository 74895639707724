export const revenueChartOptions = {
    indexAxis: 'y',
    elements: {
        bar: {
            borderWidth: 2,
        },
    },
    responsive: true,
    maintainAspectRatio: false,
    legend: {
        display: false
    },
    scales: {
        x: {
            ticks: {
                font: {
                    size: 16
                },
                callback: (value) => '€ ' + value
            }
        },
        y: { ticks: { font: { size: 16 } } }
    },

    plugins: {
        legend: {
            display: false
        },
        tooltip: {
            callbacks: {
                label: (context) => {
                    
                    var label = context.dataset.label || '';
                    if (context.parsed.y !== null) {
                        label += '  € ' + Math.round(context.parsed.x);
                    }
                    return label;
                }
            }
        },
        title: {
            display: true,
            text: 'Revenue forecast',
            font: { size: 25 }
        },
        datalabels: {
            color: 'white',
            anchor: 'end',
            align: 'left',
            formatter: (value, context) => value > 100 ? '€ ' + Math.round(value) : '',
            font: {
                size: 18,
                weight: 'bold'
            }
        }
    },
};

export const occupancyChartOptions = {
    indexAxis: 'y',
    elements: {
        bar: {
            borderWidth: 2,
        },
    },
    responsive: true,
    maintainAspectRatio: false,
    legend: {
        display:false
    },
    scales: {
        x: {
            min: 0,
            max: 100,
            ticks: {
                stepSize: 10,
                font: {
                    size: 16
                },
                callback: function (value, index, values) {
                    return value + " %";
                }
            }
        },
        y:{ticks:{font:{size:16}}}
    },
    plugins: {
          legend: {
            display: false
          },
        tooltip: {
            callbacks: {
                label: function (context) {
                    var label = context.dataset.label || '';
                    if (context.parsed.y !== null) {
                        label += ' ' + context.parsed.x + '%  ';
                    }
                    return label;
                }
            }
        },
        title: {
            display: true,
            text: 'Occupancy forecast',
            font: {size: 25}
        },
        datalabels: {
            color: 'white',
            anchor: 'end',
            align: 'left',
            formatter: (value, context) => value > 3 ? Math.round(value) + '%' : '',
            font: {
                size: 18,
                weight: 'bold'
            }
        }
    },
    
};
