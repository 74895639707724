import { Grid, TextField, MenuItem } from '@mui/material';
import React from 'react';
import DidoDrawer from '../components/Drawer'
import Container from '@mui/material/Container';
import { connect } from "react-redux";
import { withSnackbar } from 'notistack';
import LoadingIndicator from '../components/LoadingIndicator';
import { BasePage } from './BasePage';
import Toolbar from '@mui/material/Toolbar';
import AppBar from '@mui/material/AppBar';
import * as Api from '../services/api/common-api'
import NightLogDialog from './Dialog/NightLogDialog';
import NightLogItem from '../components/NightLogItem';
import { ContextMenu } from './../components/ContextMenu';
import IconButton from '@mui/material/IconButton';
import DownloadIcon from '@mui/icons-material/Download';
import Typography from '@mui/material/Typography';
import CircularProgress from '@mui/material/CircularProgress';
import './../utils/registerPrototypes'
import { isDate } from './../utils/index';

class NightLogPage extends BasePage {

    MobileWidth = 800;

    constructor(props) {
        super(props, {
            countries: [], 
            currentPropertyId: null,
            start: null,
            end:null,
            anchorEl: null,
            contextMenuItem: null,
            isFileDownloading: false
        });        
    }

    componentDidMount() {
        this.getNightLog()
    }

    currentPropertyId = () => (this.state.currentPropertyId) ?
        this.state.currentPropertyId :        
        this.props.currentUser.properties.length > 0 ?
            this.props.currentUser.properties[0].id :
                ""

    onChangeProperty = async (e) => {
        console.log(e.target.value)
        await this.setState({ currentPropertyId: e.target.value })

        this.getNightLog()
    }

    currentStart = () => {
        if (this.state.start) {
            return this.state.start;
        }
        else {
            var startDate = this.currentEnd();
            startDate.setDate(startDate.getDate() - 30)

            return startDate;
        }
    }

    onStartChange = async (e) => {
        if (isDate(e.target.value)) {
            await this.setState({ start: new Date(e.target.value) })
            this.getNightLog()
        }
    }

    currentEnd = () => this.state.end ? this.state.end : new Date();
    
    onEndChange = async (e) => {
        if (isDate(e.target.value)) {
            await this.setState({ end: new Date(e.target.value) })
            this.getNightLog()
        }
    }

    getNightLog = () => {
        this.getItems(
            Api.getNightLog,
            {
                start: this.currentStart(),
                end: this.currentEnd(),
                propertyId: this.currentPropertyId()
            },
            res => {this.setState({items: res.data})},
            "Error loading night log"
            )
    }

    showNightLogDialog = (e) => {
        this.setState({loading: true})
        Api.getAllCountries()
        .then(res => this.setState({countries: res.data, dialogOpen: true, loading: false}))
        .catch(e => this.setState({loading: false}))        
    }

    onShowContextMenu = (e, item) => this.setState({anchorEl: e.currentTarget, contextMenuItem: item});

    onContextMenuClose = (event, item, action) => {
        if (!action) {
            this.setState({ anchorEl: null})
            return;
        } 
        if (action.key === 'Edit') {
            this.setState({ anchorEl: null, currentItem: item})
            this.showNightLogDialog()
        }
        else{
            Api.deleteNightLog(item.id)
            .then(res =>
                {
                    console.log(res)
                    if (res.data === true) {
                        console.log(this.state.items)
                        this.state.items = this.state.items.filter(i => i.id !== item.id)
                        this.forceUpdate()
                    }
                });
            this.setState({ anchorEl: null})
        }
    }

    onDialogClose = (dialogresult) =>{
        this.setState({dialogOpen: false})
        console.log(this.state.items)
        if (dialogresult == null) {
            return;
        }

        var apiParam = dialogresult;
        apiParam.propertyId = this.currentPropertyId()
        
        if (this.state.currentItem == null) {
            Api.createNightLog(apiParam).then(res => {                
                this.state.items.push(res.data)
                this.forceUpdate()
            })
        }
        else {
            apiParam.id = this.state.currentItem.id
            apiParam.clonedFromBookingId = this.state.currentItem.clonedFromBookingId
            Api.updateNightLog(apiParam).then(res => {
                this.getNightLog()
                this.forceUpdate()
            })
        }                        
    }

    _downloadFileInBrowser = (binary) => {
        var binaryData = [];
        binaryData.push(binary);

        const url = window.URL.createObjectURL(new Blob(binaryData, {type: "application/pdf"}));
        const link = document.createElement('a');
        //console.log(url)
        link.href = url;
        link.setAttribute('download', 'night register.pdf'); //or any other extension
        //link.setAttribute('download', `report8.pdf`); //or any other extension

        document.body.appendChild(link);
        link.click();
        //this.setState({reportDownloadingProgress: null}) 
    }

    exportToPdf = async () => {
        await this.setState({isFileDownloading: true})
        Api.exportToPdf({start: this.currentStart(),
            end: this.currentEnd(),
            propertyId: this.currentPropertyId()})
            .then(async (res) => {                
                this._downloadFileInBrowser(res.data)
                await this.setState({isFileDownloading: false})
            });
    }

    renderToolbarDateRange = () => (
        <>        
            <TextField
                label="Start"
                type="date"
                onChange={this.onStartChange}
                value={this.currentStart().toDateString()}
                sx={{ marginRight: '10px' }}
                onKeyDown={(e) => e.preventDefault()}
                InputLabelProps={{
                    shrink: true,
                }}
            />

            <TextField
                label="End"
                type="date"
                value={this.currentEnd().toDateString()}
                style={{ marginRight: '10px' }}                
                onKeyDown={this.stopPropagation}
                InputLabelProps={{
                    shrink: true,
                }}
            />
        </>
    )
    

    renderToolbarPropertyAndDownload = () => (
        <>
            <TextField
                value={this.currentPropertyId()}
                onChange={(e) => this.onChangeProperty(e)}
                select // tell TextField to render select
                label="Property"
                sx={{ width: 220 }}
                style={{ marginRight: '10px' }}
            >
                {
                    this.props.currentUser.properties.map((p) =>
                        <MenuItem key={p.id} value={p.id}>{p.name}</MenuItem>)
                }
            </TextField>

            <IconButton
                disabled={this.state.isFileDownloading}
                onClick={this.exportToPdf}
                sx={{ mr: 2 }}
            >
                {
                    this.state.isFileDownloading ?
                        <CircularProgress size={20} /> :
                        <DownloadIcon />
                }
                <Typography variant="body2" sx={{ ml: 1, color: '#333' }} >Download</Typography>
            </IconButton>
        </>
    )

    renderAppBar = () => (
        <AppBar position="fixed" style={{ top: '56px', background: 'white' }}>
            {
                this.state.width > this.MobileWidth ?
                    <Toolbar style={{ background: 'white', minHeight: '76px' }} position="fixed">
                        {this.renderToolbarDateRange()}
                        {this.renderToolbarPropertyAndDownload()}
                    </Toolbar>
                    :
                    <>
                        <Toolbar style={{ background: 'white', minHeight: '76px' }} position="fixed">
                            {this.renderToolbarDateRange()}
                        </Toolbar>
                        <Toolbar style={{ background: 'white', minHeight: '76px' }} position="fixed">
                            {this.renderToolbarPropertyAndDownload()}
                        </Toolbar>
                    </>
            }
        </AppBar>
    )


    render() {        
        return (
            <div>
                <DidoDrawer style={{ zIndex: 2000 }}  pageTitle="Night register" onAddFabClick={async () => {await this.setState({currentItem:null});this.showNightLogDialog()}} />               
                
                {this.renderAppBar()}
                
                
                {this.state.loading ? <LoadingIndicator loading={this.state.loading} />:
                    <Container maxWidth="xl" style={{marginTop: this.state.width > this.MobileWidth ? '150px':'230px'}}>
                        <Grid container spacing={2}>
                        
                            {                                
                                this.state.items.map(item =>{
                                    
                                    return (
                                        <NightLogItem
                                        isMobile={this.state.width < this.MobileWidth}
                                        item={item}
                                        onShowContextMenu={this.onShowContextMenu}
                                        ></NightLogItem>
                                    )
                                })
                            }
                        </Grid>
                        <ContextMenu style={{ zIndex: 1100 }}
                            anchorEl={this.state.anchorEl}
                            contextMenuClose={this.onContextMenuClose}
                            contextMenuItem={this.state.contextMenuItem}
                            contextMenuOptions={[
                                {
                                    key:'Edit',
                                    title:'Edit record'
                                },
                                {
                                    key:'Delete',
                                    title:'Delete record'
                                },
                            ]}
                        />
                        <NightLogDialog
                        open={this.state.dialogOpen}
                        onDialogClose={this.onDialogClose}
                        currentItem={this.state.currentItem}
                        countries={this.state.countries}
                        />
                    </Container>
                }


            </div>);
    }
    
}

const mapStateToProps = (state) => { return {currentUser: state.didoReducer.currentUser} }
  

export default connect(mapStateToProps)(withSnackbar(NightLogPage));