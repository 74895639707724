import { Card, Grid } from '@mui/material';
import React from 'react';
import * as Api from '../services/api/common-api'
import DidoDrawer from '../components/Drawer'
import UserDialog from './Dialog/UserDialog'
import Container from '@mui/material/Container';
import Avatar from '@mui/material/Avatar';
import CardHeader from '@mui/material/CardHeader';
import CardActions from '@mui/material/CardActions';
import IconButton from '@mui/material/IconButton';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import Typography from '@mui/material/Typography';
import { SNACK_ERROR, SNACK_SUCCESS } from '../constants/common';
import { connect } from "react-redux";
import { withSnackbar } from 'notistack';
import KeyIcon from '@mui/icons-material/Key';
import { getBootstrapWidthXs } from '../utils';
import LoadingIndicator from '../components/LoadingIndicator';
import PropertiesDialog from './Dialog/PropertiesDialog';

class UsersPage extends React.Component {

    constructor(props) {
        super(props);
        this.state = { users: [], dialogOpen: false,
            dialogPropertiesOpen: false,
            editableItem: null, 
            width: window.innerWidth
        };
    }

    render() {        
                
        return (
            <div>
                <DidoDrawer style={{ zIndex: 2000 }} noFabClick pageTitle="Manage users" onAddFabClick={this.showUserDialog} />               

                
                {this.state.loading ? <LoadingIndicator loading={this.state.loading} />:
                    <Container maxWidth="xl" style={{marginTop: '10vh'}}>
                        <Grid container spacing={2}>
                        
                            {
                                
                                this.state.users.map((user) => {
                                    var w = getBootstrapWidthXs(this.state.width)
                                    console.log(user.avatar)
                                    return (
                                        <Grid item xs={w} key={user.id}>                                         
                                            <Card >
                                                <CardHeader
                                                    avatar={
                                                        <Avatar src={user.avatar}></Avatar>
                                                    }
                                                    title={
                                                        <Typography variant="h6" style={{fontWeight:'bold'}}>{user.name}</Typography>
                                                    }
                                                    subheader={
                                                        <a href={"mailto:" + user.email} style={{color: '#333'}}>{user.email}</a>
                                                        } />
                                                <CardActions disableSpacing style={{ borderTop: '1px #EEE solid', justifyContent: 'flex-end', }}>                                                    
                                                    <IconButton aria-label="Bewerk" onClick={() => this.showPropKeysDialog(user)}>
                                                        <KeyIcon />
                                                    </IconButton>
                                                    <IconButton aria-label="Bewerk" onClick={() => this.showUserDialog(user)}>
                                                        <EditIcon />
                                                    </IconButton>
                                                    <IconButton aria-label="Delete" onClick={() => this.deleteUser(user.id)}>
                                                        <DeleteIcon />
                                                    </IconButton>
                                                </CardActions>
                                            </Card>                                          
                                        </Grid>

                                    );
                                })
                            }
                        </Grid>
                        <UserDialog
                            open={this.state.dialogOpen}
                            onDialogClose={this.onUserDialogClose}
                            editableItem={this.state.editableItem}                            
                        />
                        <PropertiesDialog
                        open={this.state.dialogPropertiesOpen}
                        onDialogClose={this.onPropertiesDialogClose}
                        editableItem={this.state.editableItem}
                        properties={this.state.properties}
                        />
                    </Container>
                }


            </div>);
    }
    
    componentWillMount() {       
        window.addEventListener('resize', this.onWindowSizeChange);
        this.setState({ loading: true }, () => this.updateUsersList());        
    }

    onWindowSizeChange = () => {
        this.setState({width: window.innerWidth });
    };

    showPropKeysDialog = async (user) => {
        this.setState({ loading: true })
        Api.getAllProperties(user)
        .then(async res => await this.setState({ loading: false, dialogPropertiesOpen: true, properties: res.data }))
        console.log(user)
        };

    onPropertiesDialogClose = (dialogResult) => {
        console.log(dialogResult)
        this.setState({ dialogPropertiesOpen: false });
        if (!dialogResult) {
            return;
        }

        Api.updateProperties(dialogResult);
        
    }
    
    updateUsersList = () => {
        return Api.getUsers()
        .then(res => this.setState({ loading: false, users: res.data }))
        .catch(err =>this.logWithSnackbar("Error updating users list", this, err));
    }

    showUserDialog = async (user) => await this.setState({  editableItem: user, dialogOpen: true });

    onUserDialogClose = (dialogResult) => {
        this.setState({ dialogOpen: false });

        console.log(dialogResult)

        if (!dialogResult) {
            return;
        }

        this.setState({ loading: true });
               
        const formData = new FormData();
        
        formData.append("Name", dialogResult.name)
        formData.append("Email", dialogResult.email)
        formData.append("PhoneNumber", dialogResult.phoneNumber)
        formData.append("PlainPassword", dialogResult.plainPassword)
        formData.append("ApiKey", dialogResult.apiKey)
        formData.append("PriceLabApiKey", dialogResult.priceLabApiKey)
        
        formData.append("FormFile", dialogResult.file)        

        let promise;

        if (!this.state.editableItem) { // dialog for new user            
            promise = Api.createUserWithUpload(formData);
        }
        else {
            formData.append("id", this.state.editableItem.id)
            promise = Api.updateUserWithUpload(formData);
        }

        promise.then(() => {
            this.props.enqueueSnackbar('User successfully created', SNACK_SUCCESS)
            this.updateUsersList() })
            .catch(err =>this.logWithSnackbar("Error while creating user", this, err));
    }

    deleteUser(id) {
        Api.deleteUser(id).then(r => {this.props.enqueueSnackbar("Successfully deleted", SNACK_SUCCESS);this.updateUsersList()}).catch(err =>this.logWithSnackbar("Error deleting user "+id, this, err));
    }

    logWithSnackbar(message, error){
        this.props.enqueueSnackbar(message, SNACK_ERROR)
        Api.logger.error(`${message}`, error)
    }
}

const mapStateToProps = (state) => {
     
    return {
        currentUser: state.didoReducer.currentUser
    }
  }

export default connect(mapStateToProps)(withSnackbar(UsersPage));