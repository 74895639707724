/* eslint-disable default-case */
import React from 'react';
import * as Api from '../services/api/common-api'
import DidoDrawer from '../components/Drawer'
import { connect } from "react-redux";
import { withSnackbar } from 'notistack';
import LoadingIndicator from '../components/LoadingIndicator';
import { Eventcalendar, CalendarNav, SegmentedGroup, SegmentedItem, CalendarPrev, CalendarNext } from '@mobiscroll/react';
import '@mobiscroll/react/dist/css/mobiscroll.min.css';
import BookingDialog from './Dialog/BookingDialog';
import BlackoutDialog from './Dialog/BlackoutDialog';
import { BasePage } from './BasePage';
import { ContextMenu } from './../components/ContextMenu';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
import AgendaItem from './../components/AgendaItem';
import { styled } from '@mui/material/styles';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import PropertiesSelectorChbx from '../components/PropertiesSelectorChbx';
import ChangePriceDialog from './Dialog/ChangePriceDialog.js';

export const MyCalendar = styled(({ isEditMode, ...props }) => (
    <Eventcalendar
        theme="material"
        themeVariant="light"
        {...props}
    />))(() => ({
        ".mbsc-calendar-text": {
            height: '3.2em',

            whiteSpace: 'normal',
            overflow: 'hidden',
            textOverflow: 'ellipsis'
        },
    }));

    export const DivGrayHover = styled("div")(({ theme }) => ({
        fontSize: '20px',
        fontWeight: 'bold',
    "&: hover" : {
        background: '#CCC'
    }
}));

class CalendarPage extends BasePage {

    constructor(props) {
        super(props, {
            selectedPropertyIds: props.currentUser === undefined ? null : props.currentUser.properties.map(item => item.id),
            width: window.innerWidth,
            bookings: [],
            currentBooking: {},
            bookingDialogOpen: false,
            changePriceDialogOpen: false,
            blackoutDialogOpen: false,
            blackoutDialogFirstNight: null,
            currentBlackout: null,
            freeDayPrice: null,
            freeDayPriceBooking: null,
            loading: true,
            anchorEl: null,
            contextMenuItem: null,
            calendarViewType: 'month',
            calendarViewOptions: {
                calendar: { type: 'month' },
                agenda: { type: 'month' }
            }
        });
        this.myRef = React.createRef()
    }

    onPropertySelect = async (event) => {
        const value = event.target.value;

        if (value[value.length - 1] === "all") {
            await this.setState({ selectedPropertyIds: this.state.selectedPropertyIds.length === this.props.currentUser.properties.length ? [] : this.props.currentUser.properties.map(p => p.id) })
            this.updateCalendar(this.state.prevFirstDay, this.state.prevLastDay);
            return;
        }

        const selectedPropertyIds = typeof value === 'string' ? value.split(',') : value;
        await this.setState({ selectedPropertyIds: selectedPropertyIds });
        this.updateCalendar(this.state.prevFirstDay, this.state.prevLastDay);
    };

    onShowContextMenu = (e, item) => this.setState({ anchorEl: e.domEvent.target, contextMenuItem: item });

    onContextMenuClose = (event, item, action) => {
        console.log(item)
        if (!action) {
            this.setState({ anchorEl: null })
            return;
        }
        if (action.key === 'Edit') {
            this.setState({ anchorEl: null, currentBlackout: item, blackoutDialogOpen: true })
            return;
        }
        else {
            Api.deleteBlackout({ id: item.id, propId: item.propId })
                .then(res => {
                    console.log(res)
                    if (res.data === true) {
                        var newBookings = JSON.parse(JSON.stringify(this.state.bookings));
                        this.setState({ bookings: newBookings.filter(i => i.id !== item.id) })
                    }
                });
            this.setState({ anchorEl: null })
        }
    }

    onPageLoading = (event, inst) => {

        var firstDay = event.firstDay;
        var lastDay = event.lastDay;

        this.setState({ prevFirstDay: firstDay, prevLastDay: lastDay })

        this.updateCalendar(firstDay, lastDay);
    }

    onFabButtonClick = () => this.setState({ currentBlackout: null, blackoutDialogOpen: true })

    onEventOrAgendaClick = (item) => {

        this.setState({ loading: true });
        Api.getBooking(item.id, item.propId).then(r => {
            console.log(r)
            this.setState({ loading: false, currentBooking: r.data, bookingDialogOpen: true });
        })
            .catch(err => {
                this.setState({ loading: false });
                console.log(err)
            })
    }

    onEventClick = async (event) => {
        console.log(event)
        if (event.event.isBlackout) {
            this.onShowContextMenu(event, event.event)

            return;
        }

        if (event.event.freeDayPrice !== null) {
            this.setState({ changePriceDialogOpen: true, freeDayPrice: event.event.freeDayPrice, freeDayPriceBooking: event.event})

            return;
        }

        this.onEventOrAgendaClick(event.event)
    }

    onCellClick = async (event) => {
        if (event.events != null && event.events.length === 1 && event.events[0].freeDayPrice !== null) {
            this.setState({ changePriceDialogOpen: true, freeDayPrice: event.events[0].freeDayPrice, freeDayPriceBooking: event.events[0]})
            return;
        }

        if (event.events == null || event.events.every(e => e.isCanceled)) {
            console.log(event.date)
            await this.setState({ blackoutDialogFirstNight: event.date, blackoutDialogOpen: true })
        }
    }

    onAgendaItemClick = async (event, item) => {
        console.log(event)
        this.onEventOrAgendaClick(item)
    }

    onCalendarViewTypeChange = async (event) => {
        console.log(this.state.calendarViewType)
        let view;
        switch (event.target.value) {
            case 'month':
                view = {
                    calendar: { type: 'month' },
                    agenda: { type: 'month' }
                };
                break;
            case 'week':
                view = {
                    calendar: { type: 'week' },
                    agenda: { type: 'week' }
                };
                break;
        }
        await this.setState({ calendarViewType: event.target.value, calendarViewOptions: view })
        console.log(this.state.calendarViewType)

    }

    onBookingDialogClose = (dialogResultOkCancel, dialogState) => {
        this.setState({ bookingDialogOpen: false })
    }

    onPriceDialogClose = async (dialogResult ) => {
        console.log(dialogResult)
        this.setState({ changePriceDialogOpen: false})

        if (dialogResult == null) return;

        

        this.setState({  loading: true })

        Api.updatePrice(dialogResult).then((res) =>{
            console.log(res.data)
            if (res.data) {
                let bookings = this.state.bookings;
                let booking = bookings.find(b => b.id === dialogResult.id);
                console.log(booking)
                booking.freeDayPrice.price = dialogResult.price;
                console.log(bookings)
                this.setState({bookings: bookings})
                this.forceUpdate();                
            }
        })
        .catch(err => Api.logger.errorWithSnackbar(`Error updating price. Check logs`, this, err))
        .finally(() =>{ 
            this.setState({loading: false});
            
        })

        if (this.myRef ){
            console.log(this.myRef)
            this.myRef.current().click();
        }
    }

    onBlackoutDialogClose = async (dialogResult) => {
        this.setState({ blackoutDialogOpen: false })
        console.log(dialogResult)

        if (dialogResult == null) return;

        this.setState({ loading: true })

        if (this.state.currentBlackout != null) {
            Api.updateBlackout(dialogResult).then(res => {
                this.setState({ loading: false })
                if (res.data !== true) return;

                var newBookings = JSON.parse(JSON.stringify(this.state.bookings));

                var updatedBookings = newBookings.filter(b => b.id !== dialogResult.id)
                dialogResult.start = dialogResult.firstNight;
                dialogResult.end = dialogResult.lastNight;
                dialogResult.color = "#333"
                dialogResult.isBlackout = true;
                dialogResult.title = this.props.currentUser?.properties.find(p => p.propId === dialogResult.propId).name
                updatedBookings.push(dialogResult)

                this.setState({
                    bookings: updatedBookings
                })
            })


        }
        else {

            Api.createBlackout(dialogResult).then(async res => {
                var newBookings = JSON.parse(JSON.stringify(this.state.bookings));
                newBookings.push(res.data)

                await this.setState({ loading: false, bookings: newBookings })
            })
        }

        await this.setState({ currentBlackout: null })

    }

    updateCalendar = (start, end) => {
        this.setState({ loading: true })

        if (!start && !end) {
            var date = new Date(), y = date.getFullYear(), m = date.getMonth();
            start = new Date(y, m, 1);
            end = new Date(y, m + 1, 0);
        }

        var filter = { start: start, end: end, propertyIds: this.state.selectedPropertyIds == null ? [] : this.state.selectedPropertyIds };
        Api.getCalendarView(filter)
            .then(res => {
                this.setState({ bookings: res.data, loading: false })
            })
            .catch(err => {
                this.setState({ loading: false })
                Api.logger.errorWithSnackbar(`Error retriving calendar`, this, err)
            });
    };

    renderHeaderF = () => {
        return <React.Fragment>
            <CalendarNav className="cal-header-nav" />
            <div className="cal-header-picker">
                <SegmentedGroup value={this.state.calendarViewType} onChange={this.onCalendarViewTypeChange}>
                    <SegmentedItem value="month" icon="material-event-note" />
                    <SegmentedItem value="week" icon="material-date-range" />
                </SegmentedGroup>
            </div>
            <CalendarPrev className="cal-header-prev" />
            <CalendarNext className="cal-header-next" />
        </React.Fragment>;
    }

    renderDay = (data) => {
        if (data.original.freeDayPrice != null){
            //console.log(data);
            return (
                <DivGrayHover style={{fontSize: '14px', fontWeight: 'bold'}}>€ {data.original.freeDayPrice.price}</DivGrayHover>

            )
        }

        return data.original.title        
    }

    onCurrentUserInit = (data) => {
        console.log("onCurrentUserInit {")
        if (this.state.selectedPropertyIds === null) {
            console.log(this.props.currentUser.properties.map(item => item.id))
            this.setState({ selectedPropertyIds: this.props.currentUser.properties.map(item => item.id), loading: false })
            this.updateCalendar(this.state.prevFirstDay, this.state.prevLastDay);
            //this.forceUpdate();
        }
        console.log("onCurrentUserInit }")
    }

    render() {
        const desktopView = {
            calendar: {
                type: 'month',
                labels: 3 //true
            }
        };

        const mobileView = {
            calendar: {
                type: this.state.calendarViewType,
                labels: this.state.calendarViewType === 'month' ? 3 : 'all',
                size: 1
            },
            eventList: {
                size: 2
            }
        };

        const currentView = this.isMobile() ? mobileView : desktopView;

        return (
            <div>
                <DidoDrawer style={{ zIndex: 2000 }} pageTitle="Calendar" onAddFabClick={this.onFabButtonClick} onCurrentUserInit={this.onCurrentUserInit} />
                <LoadingIndicator loading={this.state.loading} />
                {
                    !this.state.loading && this.props.currentUser?.properties.length > 1 ?
                        <AppBar position="fixed" style={{ top: '56px', background: 'white' }}>
                            <Toolbar style={{ background: 'white', minHeight: '76px' }} position="fixed">
                                <PropertiesSelectorChbx
                                    selectedIds={this.state.selectedPropertyIds == null ? [] : this.state.selectedPropertyIds}
                                    properties={this.props.currentUser?.properties}
                                    onChange={this.onPropertySelect}
                                    isMobile={this.isMobile()}
                                />
                            </Toolbar>
                        </AppBar>
                        : <></>
                }

                <div style={{ marginTop: !this.state.loading && this.props.currentUser?.properties.length > 1 ? '170px' : '100px', marginLeft: '3vw', marginRight: '3vw' }}>

                    <MyCalendar
                        
                        renderHeader={this.isMobile() ? this.renderHeaderF : undefined}
                        renderLabelContent={this.renderDay}
                        //renderLabel={this.renderLabelF}
                        showEventCount={true}
                        animate="swing"
                        calendarScroll="horizontal"
                        view={currentView}
                        onCellClick={this.onCellClick}
                        onEventClick={this.onEventClick}
                        onPageLoading={this.onPageLoading}
                        data={this.state.bookings}
                    ></MyCalendar>

                    {this.isMobile() ?
                        <>
                            <div style={{ marginBottom: '10px', marginTop: '10px', }}><Typography variant="subtitle1" sx={{ fontWeight: 'bold' }}>Next Arrivals / Departures </Typography> </div>
                            <Stack spacing={2}>
                                {
                                    this.state.bookings.filter(b => b.bookingTimePosition !== 0 && b.isCanceled !== true && b.freeDayPrice == null)
                                        .map(b => (<AgendaItem item={b} onClick={(e) => this.onAgendaItemClick(e, b)} />))
                                }
                            </Stack>
                        </>
                        : ''
                    }

                </div>
                <BookingDialog
                    open={this.state.bookingDialogOpen}
                    onDialogClose={this.onBookingDialogClose}
                    booking={this.state.currentBooking}
                />
                <ContextMenu style={{ zIndex: 1100 }}
                    anchorEl={this.state.anchorEl}
                    contextMenuClose={this.onContextMenuClose}
                    contextMenuItem={this.state.contextMenuItem}
                    contextMenuOptions={[
                        {
                            key: 'Edit',
                            title: 'Edit blackout'
                        },
                        {
                            key: 'Delete',
                            title: 'Delete blackout'
                        },
                    ]}
                />
                <BlackoutDialog
                    open={this.state.blackoutDialogOpen}
                    onDialogClose={this.onBlackoutDialogClose}
                    firstNight={this.state.blackoutDialogFirstNight}
                    currentItem={this.state.currentBlackout}
                    properties={this.props.currentUser?.properties}
                />
                <ChangePriceDialog 
                    open={this.state.changePriceDialogOpen}
                    onDialogClose={this.onPriceDialogClose}
                    freeDayPrice={this.state.freeDayPrice}
                    freeDayPriceBooking={this.state.freeDayPriceBooking}
                />

            </div>);
    }

}

const mapStateToProps = (state) => {

    return {
        currentUser: state.didoReducer.currentUser
    }
}

export default withSnackbar(connect(mapStateToProps)(CalendarPage)); 