import { styled } from '@mui/material';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import  IconButton from '@mui/material/IconButton';
import { MoreHoriz } from '@mui/icons-material';
import React from "react";
import moment from 'moment';
import ReactCountryFlag from "react-country-flag"


const ActivePaper = styled(Paper, {
    // indicate that this prop name should be used to conditionally
    // style the component only and should not be spread to the DOM element.
    shouldForwardProp: (propName) => propName !== "isMobile"
  })(({ theme, isMobile }) => ({
    marginTop: '5px',
    marginBottom: '5px',
    marginLeft: isMobile ? '15px' : '0',
    //height: '50px',
    verticalAlign: 'middle',
    background: 'white',    
    width:'100%',
    '&:hover': {
        background: '#EEE'
    }
}));

const GridItem = styled(Grid)(({ theme }) => ({
    overflow: 'hidden',
    whiteSpace: 'nowrap', 
    textOverflow: 'ellipsis',
}));

class NightLogItem extends React.Component {
    
    renderMobile = (item) => (
        <Grid container maxWidth="xl" spacing={0} alignItems="center" justify="center" key={item.id}>
            <GridItem item xs={5} style={{ marginTop: '5px', borderRight: '1px #CCC solid', textAlign: 'center' }}>
                {moment(item.start).format("D MMM")}- {moment(item.end).format("D MMM")}
            </GridItem>
            <GridItem item xs={7} style={{ textAlign: 'center' }}>
                {item.guestName}
            </GridItem>

            <GridItem item xs={10} style={{ textAlign: 'center' }}>
                <ReactCountryFlag countryCode={item.country?.code?.toUpperCase()} svg style={{ marginRight: '10px', marginLeft: '5px' }} />
                {item.country?.name} / {item.city?.name}
            </GridItem>
            <Grid item xs={2} style={{ textAlign: 'center'}}>
                <IconButton onClick={(e) => this.props.onShowContextMenu(e, item)}>
                    <MoreHoriz color={'primary'} />
                </IconButton>
            </Grid>

            <GridItem item xs={12} style={{ textAlign: 'center' }}>
                {item.guestDocumentType === 0 ? 'Passport' : 'Driver license'}
            </GridItem>            
        </Grid>
    )

    renderDesktop = (item) => (
        <Grid container maxWidth="xl" spacing={0} alignItems="center" justify="center" key={item.id}>
            <GridItem item xs={this.props.isMobile ? 5 : 2} style={{ marginTop: '5px', borderRight: '1px #CCC solid', textAlign: 'center' }}>
                {moment(item.start).format("D MMM")}- {moment(item.end).format("D MMM")}
            </GridItem>
            <GridItem item xs={this.props.isMobile ? 7 : 4} style={{ textAlign: 'center' }}>
                {item.guestName}
            </GridItem>

            <GridItem item xs={this.props.isMobile ? 11 : 2} style={{ textAlign: 'left' }}>
                <ReactCountryFlag countryCode={item.country?.code?.toUpperCase()} svg style={{ marginRight: '10px' }} />
                {item.country?.name} / {item.city?.name}
            </GridItem>

            <GridItem item xs={this.props.isMobile ? 12 : 3} style={{ textAlign: 'center' }}>
                {item.guestDocumentType === 0 ? 'Passport' : 'Driver license'}
            </GridItem>

            <Grid item xs={1} style={{ textAlign: 'center' }}>
                <IconButton onClick={(e) => this.props.onShowContextMenu(e, item)}>
                    <MoreHoriz color={'primary'} />
                </IconButton>
            </Grid>
        </Grid>
    )

       render() {
        
        var item = this.props.item;
        // 2 4  2 3 1
           return (
               <ActivePaper key={item.id} isMobile={this.props.isMobile}>
                   {this.props.isMobile ?
                       this.renderMobile(item) :
                       this.renderDesktop(item)
                   }
               </ActivePaper>
           )
    }
}

export default (NightLogItem);