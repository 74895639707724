import React from 'react';
import DidoDrawer from '../components/Drawer'
import { connect } from "react-redux";
import { withSnackbar } from 'notistack'; 
import { BasePage } from './BasePage';
import * as Api from '../services/api/common-api'
import Grid from '@mui/material/Grid';
import Toolbar from '@mui/material/Toolbar';
import AppBar from '@mui/material/AppBar';

  import { Bar, Doughnut, getElementAtEvent  } from 'react-chartjs-2'
  import Paper from '@mui/material/Paper';

  import ChartDataLabels from 'chartjs-plugin-datalabels';
import  Container  from '@mui/material/Container';
import LoadingIndicator from '../components/LoadingIndicator';
import Select from '@mui/material/Select';
import ListItemText from '@mui/material/ListItemText';
import MenuItem from '@mui/material/MenuItem';
import { styled, Typography } from '@mui/material';


  const TdCaption = styled('td')(() => ({
    backgroundColor: '#BBB',
    color: 'white',
    width: '70%',
    marginLeft: 10,
    marginTop: 10,
    marginBottom: 10,
    padding: 10,
    borderBottom: '1px solid #777',
    fontWeight: '500',
    fontFamily: 'Verdana'
    // display: "flex",
    // justifyContent: "center",
    // alignItems: "flex-end"
  }));

  const TdValue  = styled('td')(() => ({
    backgroundColor: 'white',
    color: '#444',
    borderBottom: '1px solid #777',
    padding: 10,
    display: "flex",
    justifyContent: "center",
    // alignItems: "flex-end"
  }));

class ReportNeighborhoodTable extends BasePage {
    
    constructor(props) {
        super(props, {
            selectedPropertyId: props.currentUser.properties.map(p => p.id)[0],
            count: 0, 
            percentile25 : 0,
            percentile50 : 0,
            percentile75 : 0,
            percentile90 : 0,
        })

        this.myRef = React.createRef();
    };

    onClick = async (event) => {
        var elements = getElementAtEvent(this.myRef.current, event);
        if (elements.length > 0) {
            var MonthIndex = elements[0].index;

            await this.setState({ singleMonthName: this.state.items[MonthIndex].monthName, singleMonthIndex: MonthIndex, selectedMonthItems: this.state.items[MonthIndex].byOta })
            this.myRef.current.update();
        }
        console.log(getElementAtEvent(this.myRef.current, event));
    }

    componentDidMount() {
        console.log("load report")
        this.onLoadReport()
    }
  

    onLoadReport = async () => {
        // this.setState({
        //     count: 87,
        //     percentile25 : 183,
        //     percentile50 : 232,
        //     percentile75 : 303,
        //     percentile90 : 394

        // })

        // return;
        if (this.state.selectedPropertyId !== null) {
            this.getItems(Api.getNeightborhoodTableReport, this.state.selectedPropertyId, (res) => {
                if (res.status === 200) {
                 this.setState({
                    count: res.data.count,
                    percentile25 : res.data.percentile25,
                    percentile50 : res.data.percentile50,
                    percentile75 : res.data.percentile75,
                    percentile90 : res.data.percentile90

                })
                this.forceUpdate()
            }
            },
                "Error loading  data")
        }
        else {
            console.log("empty state")
            await this.setState({
                count: 0, 
                percentile25 : 0,
                percentile50 : 0,
                percentile75 : 0,
                percentile90 : 0,
            })
            this.forceUpdate()
        }
    }

    onPropertySelect = async (event) => {

        await this.setState({ selectedPropertyId : event.target.value });
        
        this.onLoadReport()
    };

    

    render() {

     
        

        return (
            <div>
                <DidoDrawer style={{ zIndex: 2000 }}
                    pageTitle="Neighborhood prices"
                    noFab
                />
               {
                    this.props.currentUser.properties.length > 1 ?
                        <AppBar position="fixed" style={{ top: '56px', background: 'white' }}>
                            <Toolbar style={{ background: 'white', minHeight: '76px' }} position="fixed">
                                <Select
                                    value={this.state.selectedPropertyId}
                                    
                                    onChange={this.onPropertySelect}
                                >
                                    {this.props.currentUser.properties.map((property) => (
                                        <MenuItem key={property.id} value={property.id}>
                                        
                                        <ListItemText primary={property.name} />
                                      </MenuItem>
                                    ))}
                                    </Select>
                            </Toolbar>
                        </AppBar>
                        : <></>
                }
                <LoadingIndicator loading={this.state.loading} />
                <Container maxWidth="sm" style={{ marginTop: this.props.currentUser.properties.length > 1 ? '200px' : '130px', minHeight: '50vh' }}>
                <Typography variant="h4">Overview</Typography>
                <Typography variant="body1" style={{marginBottom: 15, marginTop: 10}}>There are <strong>{this.state.count}</strong> neighborhood listings. Their median price is <strong>{this.state.percentile50} €</strong> </Typography>
                    <Paper>
                        <table border="0" width="100%" cellSpacing="0">
                            <tr>
                                <TdCaption>Neighborhood listings count</TdCaption>
                                <TdValue>{this.state.count}</TdValue>
                            </tr>
                            <tr>
                                <TdCaption>25th Percentile Price </TdCaption>
                                <TdValue>{this.state.percentile25} €</TdValue>
                            </tr>
                            <tr>
                                <TdCaption>50th Percentile Price </TdCaption>
                                <TdValue>{this.state.percentile50} €</TdValue>
                            </tr>
                            <tr>
                                <TdCaption>75th Percentile Price </TdCaption>
                                <TdValue>{this.state.percentile75} €</TdValue>
                            </tr>
                            <tr>
                                <TdCaption>90th Percentile Price </TdCaption>
                                <TdValue>{this.state.percentile90} €</TdValue>
                            </tr>
                        </table>
                    </Paper>
                    <Typography variant="subtitle2" style={{ marginTop: 10}}>Want to know more about <a href="https://simple.wikipedia.org/wiki/Percentile" target="_blank" rel="noreferrer">percentiles</a>?</Typography>
                </Container>
            </div>);
    }


}

const mapStateToProps = (state) =>  ({  currentUser: state.didoReducer.currentUser })  
  
export default withSnackbar(connect(mapStateToProps)(ReportNeighborhoodTable));