import React from 'react';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import * as Api from '../services/api/common-api'
import history from '../utils/history';
import { withSnackbar } from 'notistack';
import { Link, styled } from '@mui/material';
import { TOKEN_KEY } from '../constants/common';
import { FormRow } from './../components/FormRow';
import LoadingIndicator from '../components/LoadingIndicator';
import { GreenButton } from './../components/GreenButton';

 
class LoginPage extends React.Component {
    constructor(props) {
        super(props);
        this.state = { email: '', password:'', width: window.innerWidth, loading: false };
    }

    componentWillMount = () => window.addEventListener('resize', this.onWindowSizeChange)
    
    componentWillUnmount = () => window.removeEventListener('resize', this.onWindowSizeChange)

    onWindowSizeChange = () => this.setState({width: window.innerWidth })

    onEmailChange = async (e) => await this.setState({ email: e.currentTarget.value })

    onPasswordChange = async (e) => await this.setState({ password: e.currentTarget.value })

    tryLogin = (e) => {
        
        this.setState({loading: true})
        const data = { email: this.state.email, password: this.state.password };
        
        Api.logIn(data).then(response => {
            if (response.status === 200) {
                localStorage.setItem(TOKEN_KEY, response.data);
                window.location = '/calendar';
            }
            else {
                window.location.href = "/login";
            }

        }).catch(err => {
            console.log("Err")
            console.log(err)
            this.setState({loading: false})
            Api.logger.errorWithSnackbar(`Error while logging in, check if the user's email address is valid`, this, err)});
    }


    render() {
        return (
            <div>
                <LoadingIndicator loading={this.state.loading} />
                <Grid container spacing={0} direction="column" alignItems="center" justify="center" style={{ minHeight: '100vh' }}>
                    <Grid item alignItems="center">
                        <Paper style={{ textAlign: "center", verticalAlign: "center", width: "300px", height: '500px', marginTop: '20vh' }}>
                            <FormRow>
                                <img src="/img/login-logo.png" alt="Login" width="240px" height="74px" style={{ textAlign: "center", marginTop: "7vh" }} />
                            </FormRow>
                            <FormRow style={{ marginTop: "5vh" }}>
                                <TextField
                                    name="email"
                                    required
                                    label="E-mail"
                                    margin="dense"
                                    onChange={this.onEmailChange}
                                />
                            </FormRow>
                            <FormRow>
                                <TextField
                                    type="password"
                                    name="password"
                                    required
                                    label="Password"
                                    margin="dense"
                                    onChange={this.onPasswordChange}
                                />
                            </FormRow>
                            <FormRow><Link href="/forgot_password">Forgot password?</Link></FormRow>
                            <FormRow style={{ marginTop: "20px" }}>
                                <GreenButton variant="contained" type="submit" onClick={this.tryLogin}>LOGIN</GreenButton>
                            </FormRow>
                        </Paper>
                    </Grid>
                </Grid>
            </div>
        );
    }
}

export default  withSnackbar(LoginPage);