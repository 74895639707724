import axios from 'axios';
import {TOKEN_KEY, UNAUTHORIZED_HTTP_CODE} from "../../constants/common";


var instance = axios.create({
  baseURL: process.env.REACT_APP_API_HOST + '/api/',
});

if (localStorage.getItem(TOKEN_KEY)) {
  // Alter defaults after instance has been created
  instance.defaults.headers.common['Authorization'] = 'Bearer ' + localStorage.getItem(TOKEN_KEY);
}

// Add a 401 response interceptor
instance.interceptors.response.use(function (response) {
  return response;
}, function (error) {
    if (error.response!=null && error.response.status === UNAUTHORIZED_HTTP_CODE) {
    instance.defaults.headers.common['Authorization'] = '';
    localStorage.removeItem(TOKEN_KEY);
    if (window.location.pathname !== '/login') {      
      window.location = '/login';
    }
  } else {
    return Promise.reject(error);
  }
});

export default instance;