import React from 'react';
import Drawer from '@mui/material/Drawer';
import CssBaseline from '@mui/material/CssBaseline';
import MuiAppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import List from '@mui/material/List';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import TodayOutlined from "@mui/icons-material/esm/TodayOutlined";
import * as Api from "../services/api/common-api"
import Avatar from "@mui/material/Avatar"
import { TOKEN_KEY, USER_KEY, SNACK_SUCCESS, SNACK_ERROR } from './../constants/common';
import { connect } from "react-redux";
import actions from "../actions.js";
import Fab from '@mui/material/Fab';
import AddIcon from '@mui/icons-material/Add';
import { withSnackbar } from 'notistack';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import packageInfo from '../../package.json';
import { JwtUserCardBadge } from '../helpers/jwt';
import DashboardIcon from '@mui/icons-material/Dashboard';

import BedtimeIcon from '@mui/icons-material/Bedtime';
import SettingsIcon from '@mui/icons-material/Settings';
import BadgeIcon from '@mui/icons-material/Badge';
import LogoutIcon from '@mui/icons-material/Logout';
import UserSettingsDialog from './../containers/Dialog/UserSettingsDialog';
import SignalCellularAltIcon from '@mui/icons-material/SignalCellularAlt';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import Collapse from '@mui/material/Collapse';

const drawerWidth = 340;


const AppBar = styled(MuiAppBar,
    {
        shouldForwardProp: (prop) => prop !== 'open',
    })
    (({ theme, open }) => ({
        backgroundColor: '#609D9E',
        transition: theme.transitions.create(['margin', 'width'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        ...(open && {
            width: `calc(100% - ${drawerWidth}px)`,
            backgroundColor: '#609D9E',
            marginLeft: `${drawerWidth}px`,
            transition: theme.transitions.create(['margin', 'width'], {
                easing: theme.transitions.easing.easeOut,
                duration: theme.transitions.duration.enteringScreen,
            }),
        }),
    }));

const DrawerHeader = styled('div')(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
    justifyContent: 'flex-end',
}));

const DrawerH = styled('div')(({ theme }) => ({
    display: 'inline-flex',
    alignItems: 'center',
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
    justifyContent: 'space-between',
}));



class DidoDrawer extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            open: false,
            user: {},
            teams: [],
            locations: [],
            reportsExpanded: false,
            width: window.innerWidth,
            settingsDialogOpen: false
        }
    }

    handleDrawerOpen = () => {
        this.setState({ open: true });
    }

    handleDrawerClose = () => {
        this.setState({ open: false });
    }

    componentDidMount() {
        window.addEventListener('resize', this.onWindowSizeChange);
        //console.log("this.getCurrentUser()")
        this.getCurrentUser();
    }

    expandReports = () => {
        this.setState({ reportsExpanded: !this.state.reportsExpanded })
    }

    onWindowSizeChange = () => {
        this.setState({ width: window.innerWidth });
    }

    onSettingsItemClick = async () => {
        await this.setState({ settingsDialogOpen: true })
    }

    onSettingsDialogClose = async (dialogResult) => {
        console.log(dialogResult);

        await this.setState({ settingsDialogOpen: false })
        if (dialogResult != null) {
            Api.updateUserSettings(dialogResult)
                .then(res => {
                    console.log(res)
                    if (res.data.error === null) {
                        Api.getCurrentUser()
                            .then(user => {
                                this.setState({ user: user.data })

                                this.props.setCurrentUser(user.data)
                            })
                            .catch(err => Api.logger.errorWithSnackbar(`Error in getCurrentUser`, this, err));
                        this.props.enqueueSnackbar('Settings successfuly updated', SNACK_SUCCESS)
                    }
                    else {
                        this.props.enqueueSnackbar('Error updating password: ' + res.data.error, SNACK_ERROR)
                    }
                })
                .catch(err => Api.logger.errorWithSnackbar('Error updating settings', this, err))
        }
    }

    getCurrentUser = () => {
        //console.log(this.props.currentUser)
        if (this.props.currentUser != null) {
            this.setState({
                user: this.props.currentUser,
            })
        }
        else {
            Api.getCurrentUser()
                .then(user => {
                    this.setState({
                        user: user.data,

                    })

                    this.props.setCurrentUser(user.data)
                    this.props.onCurrentUserInit(user.data)

                })
                .catch(err => {
                    console.log(err)
                    Api.logger.errorWithSnackbar(`Error in getCurrentUser`, this, err)
                });
        }
    }

    isSystemAdmin = () => JwtUserCardBadge.isSystemAdmin()


    logout = () => {
        localStorage.removeItem(TOKEN_KEY);
        localStorage.removeItem(USER_KEY)
        window.location = '/login';
    }


    render = () => {
        //console.log(this.state.width)
        // console.log(this.props.currentUser)

        if (this.props.currentUser == null) {
            this.getCurrentUser()
        }

        return (
            <Box sx={{ display: 'flex' }}>
                <CssBaseline />
                <AppBar
                    position="fixed"
                    open={this.state.open}
                >
                    <Toolbar>
                        <IconButton
                            color="inherit"
                            aria-label="open drawer"
                            onClick={this.handleDrawerOpen}
                            edge="start"
                            sx={{ mr: 2, ...(this.state.open && { display: 'none' }) }}
                        >
                            <MenuIcon />
                        </IconButton>
                        <Typography variant="h6" noWrap sx={{ flexGrow: 1 }}>
                            {this.props.pageTitle}
                        </Typography>


                    </Toolbar>
                </AppBar>

                <Drawer
                    onClose={this.handleDrawerClose}
                    anchor="left"
                    variant="temporary"
                    open={this.state.open}
                    sx={{
                        width: drawerWidth,
                        flexShrink: 0,
                        '& .MuiDrawer-paper': {
                            width: drawerWidth,
                            boxSizing: 'border-box',
                        },
                    }}
                >
                    <DrawerH>
                        <a href="/" style={{ marginLeft: '10px' }}><img src="/img/login-logo.png" alt="BnB-Robot" width="120px" height="37px" />  </a>
                        <Typography variant='caption'>{packageInfo.version}</Typography>
                        <DrawerHeader>
                            <div align="marginRight">
                                <IconButton onClick={this.handleDrawerClose}>
                                    <ChevronLeftIcon />
                                </IconButton>
                            </div>
                        </DrawerHeader>
                    </DrawerH>
                    <Divider />

                    {this.state.user.userName ?
                        <ListItem button key={"User"} >
                            <Avatar src={this.state.user.avatar} />
                            <ListItemText primary={" " + this.state.user.userName} style={{ marginLeft: '20px' }} />
                        </ListItem> :
                        <ListItem button key={"User"} >Loading...</ListItem>
                    }
                    <Divider />
                    <List>

                        <ListItem button key={"Dashboard"} component="a" href="/dashboard">
                            <ListItemIcon><DashboardIcon /></ListItemIcon>
                            <ListItemText primary={"Dashboard"} />
                        </ListItem>

                        <Box>
                            <ListItem button key={"Reports"} onClick={this.expandReports}>
                                <ListItemIcon><SignalCellularAltIcon /></ListItemIcon>
                                <ListItemText primary={"Reports"} />
                                {this.state.reportsExpanded ? <ExpandLess /> : <ExpandMore />}
                            </ListItem>

                            <Collapse in={this.state.reportsExpanded} timeout="auto" unmountOnExit>
                                <List component="div" disablePadding style={{ paddingLeft: '1vw' }} >


                                    <ListItem button key={"Revenue"} component="a" href="/report_revenue">
                                        <ListItemIcon><SignalCellularAltIcon /></ListItemIcon>
                                        <ListItemText primary={"Revenue"} />
                                    </ListItem>

                                    <ListItem button key={"Occupancy"} component="a" href="/report_occupancy">
                                        <ListItemIcon><SignalCellularAltIcon /></ListItemIcon>
                                        <ListItemText primary={"Occupancy"} />
                                    </ListItem>

                                    <ListItem button key={"Prices"} component="a" href="/report_prices">
                                        <ListItemIcon><SignalCellularAltIcon /></ListItemIcon>
                                        <ListItemText primary={"Neighborhood prices"} />
                                    </ListItem>
                                </List>
                            </Collapse>
                        </Box>

                        <ListItem button key={"Calendar"} component="a" href="/calendar">
                            <ListItemIcon><TodayOutlined /></ListItemIcon>
                            <ListItemText primary={"Calendar"} />
                        </ListItem>


                        <ListItem button key={"NightLog"} component="a" href="/nightLog">
                            <ListItemIcon><BedtimeIcon /></ListItemIcon>
                            <ListItemText primary={"Night register"} />
                        </ListItem>


                        <ListItem button key={"Settings"} onClick={this.onSettingsItemClick}>
                            <ListItemIcon><SettingsIcon /></ListItemIcon>
                            <ListItemText primary={"Settings"} />
                        </ListItem>

                        {this.isSystemAdmin() ?
                            <ListItem button key={"UserManagement"} component="a" href="/users">
                                <ListItemIcon><BadgeIcon /></ListItemIcon>
                                <ListItemText primary={"User management"} />
                            </ListItem> : ''}

                        <ListItem button key={"Logout"} component="a" onClick={this.logout}>
                            <ListItemIcon><LogoutIcon /></ListItemIcon>
                            <ListItemText primary={"Logout"} />
                        </ListItem>


                    </List>
                </Drawer>
                {
                    (this.props.onAddFabClick == null) ? '' :
                        <Fab style={{ backgroundColor: '#4AB897', zIndex: 1200, color: 'white', right: '8vw', top: this.state.width > 800 ? '3vh' : '85vh', position: 'fixed', border: '1px solid #006847' }} aria-label="add">
                            <AddIcon onClick={() => this.props.onAddFabClick()} />
                        </Fab>
                }
                <UserSettingsDialog
                    open={this.state.settingsDialogOpen}
                    onDialogClose={this.onSettingsDialogClose}
                    fullScreen={this.state.width > 800 ? false : true}
                />
            </Box>

        );
    }
}

const mapStateToProps = (state) => {
    return {
        currentUser: state.didoReducer.currentUser
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        setCurrentUser: (user) => dispatch(actions.setCurrentUser(user))
    }
}


export default connect(mapStateToProps, mapDispatchToProps)(withSnackbar(DidoDrawer));