import React from 'react';
import DialogTitle from '@mui/material/DialogTitle';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import { FormRow } from './../../components/FormRow';
import Referer from '../../components/Referer';
import ReactCountryFlag from "react-country-flag"
import {countries} from 'country-data';
import { ReadOnlyTextField } from '../../components/ReadOnlyTextField';
import { LinkTextField } from '../../components/LinkTextField';
import '../../utils/registerPrototypes'

class BookingDialog extends React.Component {

    constructor(props) {
        super(props);

        this.state = {width:window.innerWidth}
    }

    componentWillMount() {       
        window.addEventListener('resize', this.onWindowSizeChange);
    }
    
    onWindowSizeChange = () => {
        this.setState({width: window.innerWidth });
    };

    render() {
        if (!this.props.open) {
            return(
                <div></div>
            );
        }

        console.log("BookingDialog open")
        console.log(this.props)

        return (
            <Dialog open={this.props.open}  
            //fullWidth
            
            scroll='body' 
            {...(this.state.width > 600 ? {fullWidth: true} : {fullScreen: true})}
            onClose={() => this.props.onDialogClose(0,{})}
            >
                <DialogTitle >
                <Grid container>
                        <Grid item xs={10} style={{ alignSelf: 'center' }}>
                            {`${this.props.booking.guestFirstName} ${this.props.booking.guestName}`} 
                            </Grid>                            
                        <Grid item xs={2} style={{ textAlign: 'right' }}>
                            <IconButton onClick={() => this.props.onDialogClose(0,{})}><CloseIcon /></IconButton>
                        </Grid>
                    </Grid>
                </DialogTitle>
                <DialogContent style={{ overflowY: 'visible', overflowX: 'visible' }}>
                    <div style={{ display: "flex", flexDirection: "column" }}>                    
                        <FormRow style={{marginBottom:25}}>
                            <Referer booking={this.props.booking} />
                        </FormRow>                        
                        <FormRow>
                            <ReadOnlyTextField label="Check In" defaultValue={new Date(this.props.booking.start).toDateString()} />
                            <ReadOnlyTextField label="Check Out" defaultValue={new Date(this.props.booking.end).toDateString()} />
                        </FormRow>                       
                        
                        <FormRow>
                            <ReadOnlyTextField label="First name" defaultValue={this.props.booking.guestFirstName} />
                            <ReadOnlyTextField label="Last name" defaultValue={this.props.booking.guestName} />
                        </FormRow>
                        <FormRow>
                            {
                                (this.props.booking.guestCountry && this.props.booking.guestCountry !== "") ?
                                <ReadOnlyTextField label="Country" defaultValue={countries[this.props.booking.guestCountry?.toUpperCase()]?.name}
                                InputProps={{ startAdornment: <ReactCountryFlag countryCode={this.props.booking.guestCountry?.toUpperCase()} svg style={{ marginRight: '10px' }} /> }}
                            />:
                            <ReadOnlyTextField label="Country" defaultValue="Country not specified" />

                            }
                        </FormRow>
                        <FormRow>
                            <LinkTextField label="Phone" defaultValue={this.props.booking.guestPhone} url={"tel:" + this.props.booking.guestPhone} />
                        </FormRow>
                        <FormRow>
                            <LinkTextField label="Email" defaultValue={this.props.booking.guestEmail} url={"mailto:" + this.props.booking.guestEmail} fullWidth/>
                        </FormRow>
                        <FormRow>
                            <ReadOnlyTextField label="Comments" defaultValue={this.props.booking.guestComments} multiline
      fullWidth  />
                        </FormRow>
                    </div>

                </DialogContent>
                <DialogActions>

                    <Button onClick={() => this.props.onDialogClose(0,{})} color="primary">
                        CLOSE
          </Button>
                </DialogActions>
            </Dialog>
        );
    }
}

//export default withStyles(styles, { withTheme: true })(LevelDialog);
export default BookingDialog;