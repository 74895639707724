import React from 'react';
import DialogTitle from '@mui/material/DialogTitle';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import { FormRow } from '../../components/FormRow';
import { BaseDialog } from './BaseDialog';
import { TextField, MenuItem } from '@mui/material';
import * as Api from '../../services/api/common-api'
import { isDate } from './../../utils/index';
import '../../utils/registerPrototypes'

class NightLogDialog extends BaseDialog {

    constructor(props) {
        super(props, {
            start: null,
            end: null,
            guestName: '',
            countryId: null,
            cities: [],
            cityId: null,
            guestDocumentType: 0
        });        
    }
    

    render() {
        if (!this.props.open) {
            return(
                <div></div>
            );
        }
        

        return (
            <Dialog open={this.props.open}  
            //fullWidth
            
            scroll='body' 
            {...(this.state.width > 600 ? {fullWidth: true} : {fullScreen: true})}
            onClose={this.onDialogCancel}
            >
                <DialogTitle >
                <Grid container>
                        <Grid item xs={10} style={{ alignSelf: 'center' }}>
                            { this.props.currentItem !== null ? "Edit night register record":"Add night register record"} 
                            </Grid>                            
                        <Grid item xs={2} style={{ textAlign: 'right' }}>
                            <IconButton onClick={this.onDialogCancel}><CloseIcon /></IconButton>
                        </Grid>
                    </Grid>
                </DialogTitle>
                <DialogContent style={{ overflowY: 'visible', overflowX: 'visible' }}>
                    <div style={{ display: "flex", flexDirection: "column" }}>
                        <FormRow style={{ marginBottom: 25 }}>
                            <TextField
                                label="Start"
                                type="date"
                                onChange={(e) => isDate(e.target.value) ? this.updateState('start', e) : ''}
                                value={new Date(this.currentProp('start','start','')).toDateString()}
                                sx={{ width: 300 }}
                                InputLabelProps={{
                                    shrink: true,
                                }}
                            />
                        </FormRow>                        
                        <FormRow>
                            <TextField
                                label="End"
                                type="date"
                                onChange={(e) => isDate(e.target.value) ? this.updateState('end', e) : ''}
                                value={new Date(this.currentProp('end','end','')).toDateString()}
                                sx={{ width: 300 }}
                                InputLabelProps={{
                                    shrink: true,
                                }}
                            />
                        </FormRow>
                        <FormRow>
                            <TextField
                                label="Name"                                
                                onChange={(e) => this.updateState('guestName', e)}                                
                                value={ this.currentProp('guestName','guestName','')}
                                sx={{ width: 300 }}
                                InputLabelProps={{
                                    shrink: true,
                                }}
                            />
                        </FormRow>
                        <FormRow>
                            <TextField
                                value={this.currentCountryId()}
                                onChange={this.onCountryChange}
                                sx={{ width: 300 }}
                                select // tell TextField to render select
                                label="Country"
                            >
                                {
                                    this.props.countries.map((c) =>
                                        <MenuItem key={c.id} value={c.id}>{c.name}</MenuItem>)
                                }
                            </TextField>
                        </FormRow>
                        <FormRow>
                            <TextField
                                value={this.currentCityId()}
                                onChange={(e) => this.updateState('cityId', e)}
                                sx={{ width: 300 }}
                                select // tell TextField to render select                                
                            >
                                {   
                                    this.state.cities.map((c) =>
                                        <MenuItem key={c.id} value={c.id}>{c.name}</MenuItem>)
                                }
                            </TextField>
                        </FormRow>
                        <FormRow>
                            <TextField
                                value={this.currentProp('guestDocumentType','guestDocumentType','')}
                                onChange={(e) => this.updateState('guestDocumentType', e)}
                                sx={{ width: 300 }}                                
                                select // tell TextField to render select
                                label="Document"
                            >
                                <MenuItem key={0} value={0}>Passport</MenuItem>
                                <MenuItem key={1} value={1}>Driver license</MenuItem>
                            </TextField>
                        </FormRow>
                    </div>

                </DialogContent>
                <DialogActions>
                    <Button onClick={this.onDialogSave} color="primary"> Save  </Button>
                    <Button onClick={this.onDialogCancel} color="primary"> Cancel  </Button>
                </DialogActions>
            </Dialog>
        );
    }

    componentDidMount() {
        this.loadCitesByCountryId(this.currentCountryId())
    }

    onDialogSave = async () => {
        var stateStamp = {
            start: this.currentProp('start','start',''),
            end: this.currentProp('end','end',''),
            guestName: this.currentProp('guestName','guestName',''),
            countryId: this.currentCountryId(),
            cityId: this.currentCityId(),
            guestDocumentType: this.currentProp('guestDocumentType','guestDocumentType','')
        }
        
        this.props.onDialogClose(stateStamp);
        this.flushState();
    }

    //country
    loadCitesByCountryId = (id) => {
        
        if (id === null) {
            return
        }
        Api.getAllCitiesByCountry(id)
        .then(async result => {
            await this.setState({ cities: result.data, cityId: null }) // flush city because country changed
            console.log(this.currentCityId())
            await this.setState({ cityId : this.currentCityId() })
            //console.log(this.state)
            this.forceUpdate();
        })
    }
     
    onCountryChange = async (e) => {
        await this.setState({ countryId: e.target.value})
        this.loadCitesByCountryId(this.state.countryId)
    }
    currentCountryId = () =>
        this.state.countryId != null ?
            this.state.countryId :
            this.props.currentItem != null ?
                this.props.currentItem.country?.id :
                this.props.countries.length > 0 ?
                    this.props.countries[0].id :
                    null

    //city
    onCityChange = async (e) => this.setState({ cityId: e.target.value})
    currentCityId = () => this.state.cityId != null ?
            this.state.cityId :
            this.props.currentItem != null && this.state.cities?.some(c => c.id === this.props.currentItem.city.id)?
                this.props.currentItem.city.id :
                this.state.cities.length > 0   ?
                    this.state.cities[0].id :
                    null
}

export default NightLogDialog;