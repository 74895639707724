import React from 'react';
import TextField from '@mui/material/TextField';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import * as Api from '../services/api/common-api'
import history from '../utils/history';
import { withSnackbar } from 'notistack';
import { FormRow } from '../components/FormRow';
import LoadingIndicator from '../components/LoadingIndicator';
import { BasePage } from './BasePage';
import { GreenButton } from './../components/GreenButton';

 
class ForgotPasswordPage extends BasePage {
    constructor(props) {
        super(props);
        this.state = { email:'', loading: false };
    }
   
    onEmailChange = async (e) => await this.setState({ email: e.currentTarget.value })

    sendEmail = (e) => {
        this.setState({ loading: true })
        Api.forgotPassword({ email: this.state.email })
        .then(() => window.location.href = "/check_inbox")
        .catch(err => Api.logger.errorWithSnackbar(`Error while send email`, this, err))
        .finally(() => this.setState({ loading: false }));
    }

    render() {                
        return (
            <div>
                <LoadingIndicator loading={this.state.loading} />
                <Grid container spacing={0} direction="column"
                    alignItems="center"
                    justify="center"
                    style={{ minHeight: '100vh' }}>

                    <Grid item alignItems="center">
                        <Paper style={{ textAlign: "center", verticalAlign: "center", width: "300px", height: '500px', marginTop: '20vh' }}>
                            <FormRow>
                                <img src="/img/login-logo.png" alt="Login" width="240px" height="74px" style={{ textAlign: "center", marginTop: "7vh" }} />
                            </FormRow>
                            <FormRow style={{ marginTop: "10vh" }}>
                                <TextField
                                    name="email"
                                    required
                                    label="E-mail"
                                    margin="dense"
                                    onChange={this.onEmailChange}
                                    value={this.state.email}
                                />
                            </FormRow>
                            <FormRow style={{ marginTop: "30px" }}>
                                <GreenButton variant="contained" type="submit" onClick={this.sendEmail} disabled={this.state.email.length === 0} >SEND EMAIL</GreenButton>
                            </FormRow>
                        </Paper>
                    </Grid>
                </Grid>
            </div>
        );
    }
}

export default  withSnackbar(ForgotPasswordPage);