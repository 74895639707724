import React from 'react';
import DidoDrawer from '../components/Drawer'
import { connect } from "react-redux";
import { withSnackbar } from 'notistack'; 
import { BasePage } from './BasePage';
import * as Api from '../services/api/common-api'
import Grid from '@mui/material/Grid';
import PropertiesSelectorChbx from '../components/PropertiesSelectorChbx';
import Toolbar from '@mui/material/Toolbar';
import AppBar from '@mui/material/AppBar';
import { GreenButton } from '../components/GreenButton';
import BarChartIcon from '@mui/icons-material/BarChart';
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend,
  } from 'chart.js';

  import { Bar } from 'react-chartjs-2'
  import Paper from '@mui/material/Paper';
  import Chart from 'chart.js/auto';
  import ChartDataLabels from 'chartjs-plugin-datalabels';


  ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend,
    ChartDataLabels
  );

  
const options = {
    indexAxis: 'y',
    elements: {
        bar: {
            borderWidth: 2,
        },
    },
    responsive: true,
    maintainAspectRatio: false,
    legend: {
        display:false
    },
    scales: {
        x: {
            min: 0,
            max: 100,
            ticks: {
                stepSize: 10,
                font: {
                    size: 16
                },
                callback: function (value, index, values) {
                    return value + " %";
                }
            }
        },
        y:{ticks:{font:{size:16}}}
    },
    plugins: {
          legend: {
            display: false
          },
        tooltip: {
            callbacks: {
                label: function (context) {
                    var label = context.dataset.label || '';
                    if (context.parsed.y !== null) {
                        label += ' ' + context.parsed.x + '%  ';
                    }
                    return label;
                }
            }
        },
        title: {
            display: true,
            text: 'Occupancy',
            font: {size: 25}
        },
        datalabels: {
            color: 'white',
            anchor: 'end',
            align: 'left',
            formatter: function(value, context) {
                return  Math.round(value) + '%';
              },
            font: {
                size: 18,
                weight: 'bold'
            }
        }
    },
    
};




class ReportOccupancyPage extends BasePage {
    
    constructor(props) {
        super(props, {
            selectedPropertyIds: props.currentUser.properties.map(p => p.id)
        })
    };
    
    componentDidMount() {
        this.onLoadReport()
    }

    onLoadReport = () => {
        console.log(`onLoadReport ${this.state.selectedPropertyIds}`)

         this.getItems(Api.getOccupancyReport, this.state.selectedPropertyIds, (res) => {
             console.log(this.state.items)
             this.setState({items: res.data})
             console.log(this.state.items)
             this.forceUpdate();
         },
         "Error loading night log")
    }

    onPropertySelect = async (event) => {       

        const value = event.target.value;
        
        if (value[value.length - 1] === "all") {
            this.setState({ selectedPropertyIds: this.state.selectedPropertyIds.length === this.props.currentUser.properties.length ? [] : this.props.currentUser.properties.map(p => p.id) })
            this.onLoadReport();
            return;
        }

        const selectedPropertyIds = typeof value === 'string' ? value.split(',') : value;
        await this.setState({ selectedPropertyIds : selectedPropertyIds });
        console.log(selectedPropertyIds)
        this.onLoadReport()
    };

    render() {   
        
        const labels = this.state.items.map(i => i.monthName)

        const padding = this.isMobile() ? 20:50;

        const data = {
            labels,
            datasets: [
              {
                label: 'Occupancy',
                data: this.state.items.map(i => i.occupancy * 100),
                borderColor: 'rgb(157, 188, 189)',
                backgroundColor: 'rgba(157, 188, 189, 0.8)',
              }
            ],
          };

            //  console.log(options)
            //  console.log(data)
        return (
            <div>
                <DidoDrawer style={{ zIndex: 2000 }} 
                 pageTitle="Occupancy"
                noFab 
                />
                {
                    this.props.currentUser.properties.length > 1 ?
                        <AppBar position="fixed" style={{ top: '56px', background: 'white' }}>
                            <Toolbar style={{ background: 'white', minHeight: '76px' }} position="fixed">
                                <PropertiesSelectorChbx
                                    selectedIds={this.state.selectedPropertyIds}
                                    properties={this.props.currentUser.properties}
                                    onChange={this.onPropertySelect}
                                    isMobile={this.isMobile()}
                                />
                                {/* <GreenButton startIcon={<BarChartIcon />} onClick={this.onLoadReport}>{this.isMobile() ? 'Report' : 'Build report'}</GreenButton> */}
                            </Toolbar>
                        </AppBar>
                        : <></>
                }
                <Grid container spacing={0} direction="column"
                    alignItems="center"
                    justify="center"
                    style={{ minHeight: '100vh', marginTop: this.props.currentUser.properties.length > 1 ? '170px' : '100px' }}>
                        
                    <Grid item style={{ width: '95vw' }} alignItems="center">
                    <Paper style={{ textAlign: "center", verticalAlign:"center",minHeight:this.isMobile() ? '80vh':'75vh'}}>
                        <Bar  options={options} data={data} style={{paddingLeft:padding, paddingRight: padding, paddingTop: padding, paddingBottom: padding, minHeight:this.isMobile() ? '80vh':'75vh'}} />
                        </Paper>
                    </Grid>
                </Grid>
            </div>);
    }
    
     
}

const mapStateToProps = (state) =>  ({  currentUser: state.didoReducer.currentUser })  
  
export default withSnackbar(connect(mapStateToProps)(ReportOccupancyPage));