// This is used to determine if a user is authenticated and
// if they are allowed to visit the page they navigated to.

// If they are: they proceed to the page
// If not: they are redirected to the login page.
import React from 'react'
import { Redirect, Route } from 'react-router-dom'
import { TOKEN_KEY } from './../constants/common'


const PrivateRoute = ({ component: Component, ...rest }) => {

    // Add your own authentication on the below line.
    const isLoggedIn = localStorage.getItem(TOKEN_KEY) != null;
    //console.log(rest)

    return (
        <Route
            {...rest}
            render={props =>
                isLoggedIn
                    ? (
                        <Component {...props} reportId={rest.reportId} title={rest.title}/>
                    )
                    : (
                        //// <Navigate to={{ pathname: '/login', state: { from: props.location } }} />
                        //<Navigate to='/login' />
                        <Redirect to={{ pathname: '/login', state: { from: props.location } }} />
                    )
            }

            

        />
    );
}

export default PrivateRoute