import Grid from '@mui/material/Grid';
import { Avatar } from '@mui/material';

export default function Referer(props) {

    function stringToColor(string) {
        let hash = 0;
        let i;
      
        /* eslint-disable no-bitwise */
        for (i = 0; i < string.length; i += 1) {
          hash = string.charCodeAt(i) + ((hash << 5) - hash);
        }
      
        let color = '#';
      
        for (i = 0; i < 3; i += 1) {
          const value = (hash >> (i * 8)) & 0xff;
          color += `00${value.toString(16)}`.slice(-2);
        }
        /* eslint-enable no-bitwise */
      
        return color;
      }

    function stringAvatar(name) {
        console.log(name)
        return {
          sx: {
            bgcolor: stringToColor(name),
          },
        };
      }

      console.log(props)

    var referer = props.booking.referer;

    

    var isRefererDetected = false;
    var refererImg;
    if (referer.toLowerCase() === 'booking.com') {
        isRefererDetected = true;
        refererImg = "/img/booking.com.png";
    }
    else if (referer.toLowerCase() === 'airbnb.com') {
        isRefererDetected = true;
        refererImg = "/img/airbnb.png";
    }

    return (
        <Grid container direction="row" alignItems="center" columnSpacing={2}>
            <Grid item>
                {isRefererDetected ?
                    <Avatar alt={referer} src={refererImg} /> :
                    <Avatar {...stringAvatar(referer)} />}
            </Grid>
            <Grid item>
                {referer}
            </Grid>
        </Grid>
    );
}