import React from 'react';
import DialogTitle from '@mui/material/DialogTitle';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import { FormRow } from '../../components/FormRow';
import { TextField, MenuItem, Typography } from '@mui/material';
import { BaseDialog } from './BaseDialog';
import { isDate } from '../../utils/index';
import '../../utils/registerPrototypes'
import { isNumber } from '../../helpers/jwt';
import InputAdornment from '@mui/material/InputAdornment';

class ChangePriceDialog extends BaseDialog {

    constructor(props) {
        super(props, {
            width: window.innerWidth,
            freeDayPrice: null
        });
    }

    // Current Price
    onCurrentPriceChange = async (e) => {
        var currentFreeDayPrice = this.currentFreeDayPrice();
        await this.setState({freeDayPrice: {
            id: this.props.freeDayPriceBooking.id,
            price: e.target.value,
            day: currentFreeDayPrice.day,
            propId: currentFreeDayPrice.propId
        }})        
    }

    currentFreeDayPrice = () =>
        this.state.freeDayPrice != null ? this.state.freeDayPrice :
            this.props.freeDayPrice ? this.props.freeDayPrice :
                null;
    

    onSave = async () => {
         
         this.props.onDialogClose(this.currentFreeDayPrice())
         this.flushState();
    }

    onCancel = () => this.props.onDialogClose()

    render() {
        if (!this.props.open) {
            return(
                <div></div>
            );
        }

        
        return (
            <Dialog open={this.props.open}               
            {...(this.state.width > 600 ? {fullWidth: true} : {fullScreen: true})}
            onClose={this.onCancel}
            maxWidth='xs'
            >
                <DialogTitle >
                    <Grid container>
                        <Grid item xs={10} style={{ alignSelf: 'center' }}>
                            Change price
                        </Grid>
                        <Grid item xs={2} style={{ textAlign: 'right' }}>
                            <IconButton onClick={this.onCancel}><CloseIcon /></IconButton>
                        </Grid>
                    </Grid>
                </DialogTitle>
                <DialogContent style={{ overflowY: 'visible', overflowX: 'visible' }}>
                    <FormRow style={{ marginBottom: 15}}>
                        <Typography variant='body1'>{this.props.freeDayPriceBooking.propertyName}</Typography></FormRow>
                        
                    <FormRow>
                        <TextField
                            label="Price"
                            fullWidth
                            type="number"
                            value={this.currentFreeDayPrice().price}
                            onChange={this.onCurrentPriceChange}
                            InputLabelProps={{
                                shrink: true,
                            }}
                            InputProps={{
                                startAdornment: <InputAdornment position="start">€</InputAdornment>,
                            }}
                        />
                    </FormRow>
                </DialogContent>
                <DialogActions>
                    <Button onClick={this.onSave} color="primary">
                        SAVE
                    </Button>
                    <Button onClick={this.onCancel} color="primary">
                        CANCEL
                    </Button>
                </DialogActions>
            </Dialog>
        );
    }
}


export default ChangePriceDialog;