import React from 'react';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import '@mobiscroll/react/dist/css/mobiscroll.min.css';
import { styled } from '@mui/material';
import FlightTakeoffIcon from '@mui/icons-material/FlightTakeoff';
import FlightLandIcon from '@mui/icons-material/FlightLand';
import AutoDeleteIcon from '@mui/icons-material/AutoDelete';
import HomeOutlinedIcon from '@mui/icons-material/HomeOutlined';
import BedtimeOutlinedIcon from '@mui/icons-material/BedtimeOutlined';
import GroupsOutlinedIcon from '@mui/icons-material/GroupsOutlined';
import PermIdentityOutlinedIcon from '@mui/icons-material/PermIdentityOutlined';
import moment from 'moment';
import { LinkTextField } from './LinkTextField';
import PhoneOutlinedIcon from '@mui/icons-material/PhoneOutlined';

const ActivePaper = styled(Paper)(() => ({
    //marginTop: '5px',
    //marginBottom: '5px',
    //marginLeft: '5px',
    //height: '50px',
    verticalAlign: 'middle',
    background: 'white',
    width:'100%',
    '&:hover': {
        background: '#EEE'
    }
}));

class AgendaItem extends React.Component {
    render2() {
        return "---"
    }

    render() {
        var item = this.props.item;
        var fNight = moment(item.start).format("DD.MM.YY");
        var lNight = moment(item.end).format("DD.MM.YY")
        return (
            <ActivePaper key={item.id} onClick={this.props.onClick}>
                <Grid container maxWidth="xl" spacing={0} alignItems="center" justify="center" key={item.id}>
                    <Grid container >
                        <Grid item xs={2} sx={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                            {
                                {                                    
                                    1: <FlightLandIcon  fontSize='large' sx={{color: '#4C6294'}}/>,
                                    2: <FlightTakeoffIcon  fontSize='large' sx={{color: '#DD9B65'}}/>
                                }[item.bookingTimePosition]
                            }
                        </Grid>
                        <Grid item xs={10}>
                            <Grid container>
                                <Grid item xs={12} sx={{display: 'flex', alignItems: 'left', paddingTop: '10px'}}>
                                    {
                                        {                                            
                                            1: <div style={{display: 'flex', marginLeft: '7px'}}><Typography variant="subtitle2" sx={{fontWeight: 'bold'}}><span style={{color: '#4C6294'}}>Arrival:</span><span style={{color: '#333'}}> {" on " + fNight}</span></Typography></div>,
                                            2: <div style={{display: 'flex', marginLeft: '7px'}}><Typography variant="subtitle2" sx={{fontWeight: 'bold'}}><span style={{color: '#DD9B65'}}>Departure:</span><span style={{color: '#333'}}> {" on " + lNight}</span></Typography></div>
                                        }[item.bookingTimePosition]
                                    }
                                </Grid>
                                <Grid item xs={12} sx={{ display: 'flex', alignItems: 'left' }}>
                                    <Grid container direction="row" alignItems="center">
                                        <Grid item xs={1}  sx={{display: 'flex', justifyContent: 'center'}}>
                                            <HomeOutlinedIcon sx={{ fontSize: '16px' }} />
                                        </Grid>
                                        <Grid item xs={8} sx={{overflow: 'hidden', whiteSpace: 'nowrap',  textOverflow: 'ellipsis'}}>
                                            <Typography variant="body2" sx={{ color: '#333' }}>{item.propertyName}</Typography>
                                        </Grid>
                                        <Grid item xs={1} sx={{display: 'flex', justifyContent: 'center'}}>
                                            <BedtimeOutlinedIcon sx={{ fontSize: '14px' }} />
                                        </Grid>
                                        <Grid item xs={1}>
                                            <Typography variant="body2" sx={{ color: '#333' }}>{item.days}</Typography>
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid item xs={12} sx={{display: 'flex', alignItems: 'left'}}>
                                    <Grid container direction="row" alignItems="center">
                                        <Grid item xs={1} sx={{ display: 'flex', justifyContent: 'center' }}>
                                            <PermIdentityOutlinedIcon sx={{ fontSize: '16px' }} />
                                        </Grid>
                                        <Grid item xs={8} sx={{overflow: 'hidden', whiteSpace: 'nowrap',  textOverflow: 'ellipsis'}}>
                                            <Typography variant="body2" sx={{ color: '#333' }}>{item.guestFirstName} {item.guestName} </Typography>
                                        </Grid>
                                        <Grid item xs={1} sx={{display: 'flex', justifyContent: 'center'}}>
                                            <GroupsOutlinedIcon sx={{ fontSize: '14px' }} />
                                        </Grid>
                                        <Grid item xs={1}>
                                            <Typography variant="body2" sx={{ color: '#333' }}>{item.numGuests}</Typography>
                                        </Grid>
                                    </Grid>
                                </Grid>

                                <Grid item xs={12} sx={{display: 'flex', alignItems: 'left', paddingBottom: '10px'}}>
                                    <Grid container direction="row" alignItems="center">
                                        <Grid item xs={1} sx={{ display: 'flex', justifyContent: 'center' }}>
                                            <PhoneOutlinedIcon sx={{ fontSize: '16px' }} />
                                        </Grid>
                                        <Grid item xs={10} sx={{overflow: 'hidden', whiteSpace: 'nowrap',  textOverflow: 'ellipsis'}}>
                                            <Typography variant="body2" sx={{ color: '#333' }}><a href={"tel:" + item.guestPhone} style={{textDecoration: 'underline dotted',color:'#1976d2'}}>{item.guestPhone}</a></Typography>
                                        </Grid>                                        
                                    </Grid>
                                </Grid>
                            </Grid>

                        </Grid>
                    </Grid>


                </Grid>
            </ActivePaper>
        )
    }
}


export default AgendaItem