
import moment from 'moment';

// adjust(#, 50) - light, adjust(#, -50) - dark  
export const adjust = (color, amount) => '#' + color.replace(/^#/, '').replace(/../g, color => ('0'+Math.min(255, Math.max(0, parseInt(color, 16) + amount)).toString(16)).substr(-2));

// setOpacity (#, 0.2)
export const setOpacity = (hex, alpha) => `${hex}${Math.floor(alpha * 255).toString(16).padStart(2, 0)}`;

export const formatTime = (t) => ("0" + t.getHours()).slice(-2)   + ":" + ("0" + t.getMinutes()).slice(-2)

export const getBootstrapWidthXs = (currentScreenWidth) => { //todo useMediaQuery
    
    var w = 2 + Math.floor(3000/currentScreenWidth)
                                    if (w >= 7) w = 12 // 12
                                    if (w >=5 && w<7) w = 6; // 2*6
                                    if (w >=4 && w<5 ) w = 4;
                                    if (w<4) w = 3;
                                    return w
}

export const isDate = (value) => isNaN(Date.parse(value)) === false
export const toDateString = (value) => moment(value).format("YYYY-MM-DD")
