import React from 'react';
import TextField from '@mui/material/TextField';
import { styled } from '@mui/material/styles';

export const ReadOnlyTextField = styled(({ isEditMode, ...props }) => (
    <TextField
        {...props}
        disabled={true}
        variant="outlined" />))(() => ({
            ".MuiInputBase-input.Mui-disabled": {
                WebkitTextFillColor: "#333"
            },
            ".MuiInputLabel-root.Mui-disabled": {
                color: '#999',
                fontSize: '1.2em'
            },
            ".MuiOutlinedInput-root.Mui-disabled fieldset": {
                borderColor: 'transparent',
            }
        }));
