import React from 'react';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import * as Api from '../services/api/common-api'
import history from '../utils/history';
import { withSnackbar } from 'notistack';
import { styled } from '@mui/material';
import { FormRow } from '../components/FormRow';
import LoadingIndicator from '../components/LoadingIndicator';
import { BasePage } from './BasePage';
import { TOKEN_KEY, USER_KEY, SNACK_SUCCESS, SNACK_ERROR } from './../constants/common';

const GreenButton = styled(Button)(({ theme }) => ({
    margin: theme.spacing(1),
        width: '150px',
        backgroundColor: '#4AB897',
        "&:hover": {
            //you want this to be the same as the backgroundColor above
            backgroundColor: "#4AB897"
        },
        color: 'white'
 }));

 
class ChangePasswordPage extends BasePage {
    constructor(props) {
        super(props);
        this.state = { password:'', confirmPassword: '', loading: false };
    }

   
    onPasswordChange = async (e) => {
        await this.setState({ password: e.currentTarget.value });
    }

    onConfirmPasswordChange = async (e) => {
        await this.setState({ confirmPassword: e.currentTarget.value });
    }

    tryChange = (e) => {
        if (this.state.password !== this.state.confirmPassword) {
            alert("Password and it's confirmation do not match");
            return;
        }
        this.setState({ loading: true })
        let params = new URLSearchParams(this.props.location.search)

        const data = { recoveryCode: params.get('token').replace(/\s/g, '+'), newPassword: this.state.password, email: params.get('email') };

        Api.changePasswordByRecoveryCode(data)
        .then(_ => {
            localStorage.removeItem(TOKEN_KEY);
            localStorage.removeItem(USER_KEY)
            window.location = '/login';

        })
        .catch(err => Api.logger.errorWithSnackbar(`Error while logging in, check if the user's email address is valid`, this, err))
        .finally(() => this.setState({ loading: false }));
    }

    render() {                
        return (
            <div>
                <LoadingIndicator loading={this.state.loading} />
                <Grid container spacing={0} direction="column"
                    alignItems="center"
                    justify="center"
                    style={{ minHeight: '100vh' }}>

                    <Grid item alignItems="center">
                        <Paper style={{ textAlign: "center", verticalAlign: "center", width: "300px", height: '500px', marginTop: '20vh' }}>
                            <FormRow>
                                <img src="/img/login-logo.png" alt="Login" width="240px" height="74px" style={{ textAlign: "center", marginTop: "7vh" }} />
                            </FormRow>
                            <FormRow style={{ marginTop: "5vh" }}>
                                <TextField
                                    type="password"
                                    name="password"
                                    required
                                    label="Password"
                                    margin="dense"
                                    onChange={this.onPasswordChange}
                                />
                            </FormRow>
                            <FormRow>
                                <TextField
                                    type="password"
                                    name="confirmPassword"
                                    required
                                    label="Confirmation"
                                    margin="dense"
                                    onChange={this.onConfirmPasswordChange}
                                />
                            </FormRow>
                            <FormRow style={{ marginTop: "30px" }}>
                                <GreenButton variant="contained" type="submit" onClick={this.tryChange} style={{width: '200px'}}>CHANGE PASSWORD</GreenButton>
                            </FormRow>
                        </Paper>
                    </Grid>
                </Grid>
            </div>
        );
    }
}

export default  withSnackbar(ChangePasswordPage);