import React from 'react';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';

export class ContextMenu extends React.Component {

    render() {
        //console.log(JSON.stringify(this.props.contextMenuOptions))
        return (
            <Menu                
                anchorEl={this.props.anchorEl}
                keepMounted
                open={Boolean(this.props.anchorEl)}
                onClose={this.props.contextMenuClose}>
                {this.props.contextMenuOptions.map((item) => {
                    return (
                        <MenuItem key={item.key} onClick={(e) => this.props.contextMenuClose(e, this.props.contextMenuItem, item)}>
                            {item.title}
                        </MenuItem>
                    );
                })}
            </Menu>
        );
    }
}
