import React from 'react';
import DialogTitle from '@mui/material/DialogTitle';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import { FormRow } from '../../components/FormRow';
import TextField from '@mui/material/TextField';
class PropertiesDialog extends React.Component {

    constructor(props) {
        super(props);

        this.state = {width:window.innerWidth}
    }

    componentWillMount() {       
        window.addEventListener('resize', this.onWindowSizeChange);
    }
    
    onWindowSizeChange = () => {
        this.setState({width: window.innerWidth });
    };

    onPropKeyChange = (e) => {
        console.log(e.target.id)        

        const currentProp = this.props.properties.find(prop => prop.propId === e.target.id);
        if (currentProp !== null) {
            console.log("c")
            currentProp.propKey = e.target.value;
        }
        console.log(this.props.properties)
    }

    onPriceLabIdChange = (e) => {
        const currentProp = this.props.properties.find(prop => "price_" + prop.propId === e.target.id);
        if (currentProp !== null) {
            currentProp.priceLabId = e.target.value;
        }
    }

    onOkClick = () => {        
        this.props.onDialogClose(this.props.properties);        
    }

    render() {
        if (!this.props.open) {
            return(
                <div></div>
            );
        }

        return (
            <Dialog open={this.props.open}  
            //fullWidth
            
            scroll='body' 
            {...(this.state.width > 600 ? {fullWidth: true} : {fullScreen: true})}
            onClose={() => this.props.onDialogClose(0,{})}
            >
                <DialogTitle >
                <Grid container>
                        <Grid item xs={10} style={{ alignSelf: 'center' }}>
                            Properties
                            </Grid>                            
                        <Grid item xs={2} style={{ textAlign: 'right' }}>
                            <IconButton onClick={() => this.props.onDialogClose(0,{})}><CloseIcon /></IconButton>
                        </Grid>
                    </Grid>
                </DialogTitle>
                <DialogContent style={{ overflowY: 'visible', overflowX: 'visible' }}>
                    <div style={{ display: "flex", flexDirection: "column" }}>
                        {
                            this.props.properties.map(p => {
                                return (
                                    <FormRow style={{marginTop: 30, borderBottom: '1px #EEE solid'}}>
                                        <Grid container spacing={1}>
                                            <Grid item xs={4} style={{ alignSelf: 'center' }}> {p.name} </Grid>
                                            <Grid item xs={4}>
                                                <TextField
                                                    id={p.propId}
                                                    name="propKey"
                                                    required
                                                    label="Prop key"
                                                    size="small"
                                                    defaultValue={p.propKey}
                                                    onChange={this.onPropKeyChange}
                                                    fullWidth
                                                />
                                            </Grid> 
                                            <Grid item xs={4}>
                                                <TextField
                                                    id={"price_"+p.propId}
                                                    name="PriceLab"
                                                    required
                                                    label="PriceLab id"
                                                    size="small"
                                                    defaultValue={p.priceLabId}
                                                    onChange={this.onPriceLabIdChange}
                                                    fullWidth
                                                />
                                            </Grid> 
                                        </Grid>
                                    </FormRow>
                                )
                            })
                        }
                    </div>

                </DialogContent>
                <DialogActions>
                <Button onClick={() => this.props.onDialogClose(this.props.properties)} color="primary">Save</Button>
                    <Button onClick={this.props.onDialogClose} color="primary">Cancel</Button>
                </DialogActions>
            </Dialog>
        );
    }
}

//export default withStyles(styles, { withTheme: true })(LevelDialog);
export default PropertiesDialog;