import * as Api from './services/api/common-api'
//import logger from './services/api/log-api'

var setCurrentTeam = function (team) {
    //console.log("actions/SET_TEAM")
    return {
        type: "SET_TEAM",
        team
    }
};

 var setCurrentOrg = function (org) {
    
    return {
        type: "SET_ORG",
        org
    }
}

var setCurrentUser = function (user) {
  //console.log("setCurrentUser")
  return {
    type: "SET_USER",
    user
  }
}

export const requestUnitActivities = (team) => {
    //console.log(`ACTION requestUnitActivities ${team}`)
  
    return {
      type: 'REQUEST_AGENDA_ROWS',
      team
    }
  }

  export const receivedUnitActivities = (team, data) => {
    //console.log(`ACTION receivedUnitActivities ${team}, ${JSON.stringify(data)}`)

    return {
      type: 'RECEIVE_AGENDA_ROWS',
      team,
      agendaRows: data
    }
  }


export default { setCurrentTeam, setCurrentOrg,setCurrentUser};