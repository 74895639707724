import './App.css';
import React from 'react';
import { Helmet } from 'react-helmet';
import { Switch, Route } from 'react-router-dom';
import LoginPage from "./../LoginPage"
import ChangePasswordPage from "./../ChangePasswordPage"
import PrivateRoute from '../../components/PrivateRoute';
import HomePage from './../HomePage';
import NotFoundPage from '../NotFoundPage';
import { withRouter } from 'react-router-dom';
import { isJwtExpired } from "../../helpers/jwt";
import SessionExpiredPage from './../SessionExpiredPage';
import CalendarPage from './../CalendarPage'
import UsersPage from './../UserPage'
import NightLogPage from './../NightLogPage';
import ReportOccupancyPage from './../ReportOccupancyPage';
import ReportRevenuePage from './../ReportRevenuePage';
import ReportDashboardPage from './../Dashboard/ReportDashboardPage';
import ReportNeighborhoodTable from '../ReportNeighborhoodTable';
import ForgotPasswordPage from '../ForgotPasswordPage';
import CheckInboxPage from './../CheckInboxPage';

setInterval(function(){ 
  //console.log("tick")
  let exp = isJwtExpired()
  if (exp) {
    window.location = '/session_expired'; 
  }
}, 1000000000);

class AppInternal extends React.Component {

  render() {
    return (
      <div>
        <Helmet
          titleTemplate="%s - BnB-Robot"
          defaultTitle="BnB-Robot"
          bodyAttributes={this.props.location.pathname === '/login' || 
          this.props.location.pathname === '/check_inbox' || 
          this.props.location.pathname === '/forgot_password' || 
          this.props.location.pathname === '/change_password' || 
          this.props.location.pathname === '/session_expired'?
            { style: 'background-color : #609D9E' } :
            { style: 'background-color : #EEE' }}
        >
          <meta name="description" content="Dido Meet application" />
          <meta http-equiv="cache-control" content="no-cache" />
          <meta http-equiv="expires" content="0" />
          <meta http-equiv="pragma" content="no-cache" />
        </Helmet>
        <Switch>
          <Route path='/login' component={LoginPage} />
          <Route path='/forgot_password' component={ForgotPasswordPage} />
          <Route path='/check_inbox' component={CheckInboxPage} />
          <Route path='/change_password' component={ChangePasswordPage} />
          <Route path='/session_expired' component={SessionExpiredPage} />
          <PrivateRoute path="/calendar" component={CalendarPage} />
          <PrivateRoute path="/users" component={UsersPage} />
          <PrivateRoute path="/nightLog" component={NightLogPage} />
          <PrivateRoute path="/report_occupancy" component={ReportOccupancyPage} />
          <PrivateRoute path="/report_revenue" component={ReportRevenuePage} />
          <PrivateRoute path="/report_prices" component={ReportNeighborhoodTable} />
          <PrivateRoute path="/dashboard" component={ReportDashboardPage} />
          <PrivateRoute exact path='/' component={CalendarPage} /> 
          <Route path="" component={NotFoundPage} />
        </Switch>
      </div>
      
    );
  }
}

const App = withRouter(AppInternal);
export {App};