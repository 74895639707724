import React from 'react';
import DialogTitle from '@mui/material/DialogTitle';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import { FormRow } from '../../components/FormRow';
import { TextField, MenuItem } from '@mui/material';
import { BaseDialog } from './BaseDialog';
import { isDate } from './../../utils/index';
import '../../utils/registerPrototypes'

class BlackoutDialog extends BaseDialog {

    constructor(props) {
        super(props, {
            width: window.innerWidth,
            currentPropertyId: null,
            currentFirstNight: null,
            currentLastNight: null
        });
    }

    // FirstNight
    onFirstNightChange = async (e) => {        
        if (isDate(e.target.value))
        {
            await this.setState({currentFirstNight: new Date(e.target.value)})
        }
    }

    currentFirstNight = () =>
        this.state.currentFirstNight != null ? this.state.currentFirstNight :
            this.props.currentItem?.firstNight ? new Date(this.props.currentItem.firstNight) :
                this.props.firstNight ? new Date(this.props.firstNight) :
                    new Date();
    

    // LastNight
    onLastNightChange = (e) => {
        if (isDate(e.target.value))
        {
            this.setState({currentLastNight: new Date(e.target.value)})
        }
    }

    currentLastNight = () => (this.state.currentLastNight != null) ? this.state.currentLastNight :
        this.props.currentItem?.lastNight ? new Date(this.props.currentItem.lastNight) :
            this.props.currentItem?.firstNight ? this.addDays(this.props.currentItem.firstNight, 2) :
                this.props.firstNight ? this.addDays(this.props.firstNight, 2)
                    : this.addDays(new Date(), 2);


    // Property
    onChangeProperty = (e) => {
        this.setState({currentPropertyId: e.target.value})
    }

    currentPropertyId = () => (this.state.currentPropertyId) ?
        this.state.currentPropertyId :
        (this.props.currentItem?.propId) ?
            this.props.currentItem.propId :
            this.props.properties.length > 0 ?
                this.props.properties[0].propId :
                ""

    onSave = async () => {       
         var result ={
            firstNight: this.currentFirstNight().toDateString(),
            lastNight: this.currentLastNight().toDateString(),
            propId: this.state.currentPropertyId || this.currentPropertyId(),
            id: this.props.currentItem && this.props.currentItem.id
         }
         
         this.props.onDialogClose(result)
         this.flushState();
    }

    onCancel = () => this.props.onDialogClose()

    addDays = (date, days) => {
        var result = new Date(date);
        result.setDate(result.getDate() + days);
        return result;
      }

    render() {
        if (!this.props.open) {
            return(
                <div></div>
            );
        }

        
        return (
            <Dialog open={this.props.open}  
            scroll='body' 
            {...(this.state.width > 600 ? {fullWidth: true} : {fullScreen: true})}
            onClose={this.onCancel}
            >
                <DialogTitle >
                    <Grid container>
                        <Grid item xs={10} style={{ alignSelf: 'center' }}>
                            {this.props.currentItem !== null ? "Edit blackout" : "Add blackout"}
                        </Grid>
                        <Grid item xs={2} style={{ textAlign: 'right' }}>
                            <IconButton onClick={this.onCancel}><CloseIcon /></IconButton>
                        </Grid>
                    </Grid>
                </DialogTitle>
                <DialogContent style={{ overflowY: 'visible', overflowX: 'visible' }}>
                    <div style={{ display: "flex", flexDirection: "column" }}>
                        {
                            this.props.properties && this.props.properties.length > 0 ?
                                <FormRow>
                                    <TextField
                                        value={this.currentPropertyId()}
                                        onChange={(e) => this.onChangeProperty(e)}
                                        select
                                        label="Property"
                                        fullWidth
                                    >
                                        {
                                            this.props.properties.map((p) =>
                                                <MenuItem key={p.propId} value={p.propId}>{p.name}</MenuItem>)
                                        }
                                    </TextField>
                                </FormRow>
                                : ''
                        }
                        
                        <FormRow>
                            <TextField 
                            label="First night"
                            fullWidth
                            type="date"
                            value={this.currentFirstNight().toDateString()}
                            onChange={this.onFirstNightChange}                            
                            InputLabelProps={{
                              shrink: true,
                            }}
                            />
                        </FormRow>
                        <FormRow>
                        <TextField 
                            label="Last night"
                            type="date"
                            fullWidth
                            value={this.currentLastNight().toDateString()}
                            onChange={this.onLastNightChange}
                            InputLabelProps={{
                              shrink: true,
                            }}
                            />
                        </FormRow>
                    </div>

                </DialogContent>
                <DialogActions>
                    <Button onClick={this.onSave} color="primary">
                        SAVE
                    </Button>
                    <Button onClick={this.onCancel} color="primary">
                        CANCEL
                    </Button>
                </DialogActions>
            </Dialog>
        );
    }
}


export default BlackoutDialog;