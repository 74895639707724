import { styled } from '@mui/material';
import Button from '@mui/material/Button';

export const GreenButton = styled(Button)(({ theme }) => ({
  margin: theme.spacing(1),
      width: '150px',
      backgroundColor: '#4AB897',
      "&:hover": {
          //you want this to be the same as the backgroundColor above
          backgroundColor: "#4AB897"
      },
      color: 'white'
}));