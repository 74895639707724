import React from 'react';
import TextField from '@mui/material/TextField';
import { styled } from '@mui/material/styles';

export const LinkTextField = styled(({ isEditMode, ...props }) => (
    <TextField
        {...props}
        disabled={true}
        onClick={() => {
            console.log(props);
            window.location.href = props.url;
        }}
        variant="outlined" />))(() => ({
            ".MuiInputBase-input.Mui-disabled": {
                WebkitTextFillColor: "#1976d2",
                textDecoration: 'underline dotted',
                cursor: 'pointer'
            },
            ".MuiInputLabel-root.Mui-disabled": {
                color: '#999',
                fontSize: '1.2em'
            },
            ".MuiOutlinedInput-root.Mui-disabled fieldset": {
                borderColor: 'transparent',
            }
        }));
